<template>
  <v-btn
    v-if="list.editMode"
    color="primary"
    depressed
    class="mr-3"
    @click="$emit('list-save')"
  >
    <v-icon left>
      mdi-content-save-all
    </v-icon>
    <span>保存</span>
  </v-btn>
</template>

<script>
export default {
  name: 'Save',
  props: {
    list: { type: Object, default: () => ({}) },
  },
};
</script>
