<template>
  <Confirm
    v-if="!list.editMode && canFix && !$vuetify.breakpoint.xs"
    message="確定します。よろしいですか？"
    @on-click-yes="callFix"
  >
    <template #default="{ on }">
      <v-btn
        depressed
        class="mr-3"
        :disabled="disabled"
        color="primary"
        v-on="on"
      >
        <v-icon left>
          mdi-table-check
        </v-icon>
        <span>確定</span>
      </v-btn>
    </template>
  </Confirm>
</template>

<script>
import Confirm from '@/components/common/Confirm';
import { fixRecords } from '../util';
import { mapActions } from 'vuex';

export default {
  name: 'Fix',
  components: {
    Confirm,
  },

  props: {
    // オブジェクト名
    objectName: { type: String, required: true },
    hasVersion: { type: Boolean, default: false },
    list: { type: Object, default: () => ({}) },
    canFix: { type: Boolean, default: true },
  },

  computed: {
    disabled() {
      return !this.canFix || this.list.selected.length === 0;
    },
  },

  methods: {
    async callFix() {
      await this.$store.dispatch('loading/register', this.executeFix());
    },
    async executeFix() {
      try {
        await fixRecords({
          records: this.list.selected,
          objectName: this.objectName,
          controller: this.$pageProperty.controller,
        });
        this.list.selected = [];
        // リロード
        this.$emit('reload-data');
        // 確定
        this.saveComplete('確定しました。');
      } catch (error) {
        this.openSnackBar({
          message: '確定に失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },

    ...mapActions('snackbar', ['openSnackBar', 'saveComplete']),
  },
};
</script>
