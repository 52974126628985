<template>
  <v-row v-if="fieldSet && Object.keys(fieldSet).length !== 0">
    <v-col
      v-for="(field, index) in computedFields"
      :key="`fieldSetInput-${field.fieldPath}-${index}`"
      cols="12"
    >
      <v-row>
        <v-col class="flex-grow-0">
          <v-checkbox
            v-model="targetFields"
            :value="field.fieldPath"
            hide-details
            class="mt-1"
          ></v-checkbox>
        </v-col>
        <v-col class="flex-grow-1">
          <div style="flex: 1;">
            <FormulateForm
              :name="`${_.uniqueId('fieldsetinput-')}-${index}`"
              @submit="$emit('submit', $event)"
            >
              <FormulateInput
                v-model="targetObject[field.fieldPath]"
                :name="field.fieldInfo.fieldPath"
                :label="field.fieldInfo.label"
                :hint="field.fieldInfo.helpText"
                :items="field.fieldInfo.picklistValues"
                :type="field.fieldInfo.type"
                :reference-to="field.fieldInfo.referenceTo"
                :controller="$pageProperty.controller"
                :not-apply-default-value="true"
                v-bind="{
                  ...fieldInputProps[field.fieldPath],
                  ...field.fieldInfo,
                }"
                @input="onInput($event, field.fieldPath)"
              >
                <template #label>
                  <span />
                </template>
              </FormulateInput>
            </FormulateForm>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <div v-else></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'FieldSetInput',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    // 項目セット
    fieldSet: {
      type: Object,
      default: null,
    },
    // field内のFormulateInputに橋渡しするProp
    fieldInputProps: {
      type: Object,
      default: () => ({}),
    },
    // リセット(この値の変更を検知してリセットをかける)
    doReset: {
      type: undefined,
      default: null,
    },
    // 項目情報
    fieldInfoList: {
      type: undefined,
      default: () => ({}),
    },
  },

  data: (vm) => ({
    // 入力の対象となる項目
    targetObject: vm.value || {},
    // チェックボックスの値
    targetFields: Object.keys(vm.value || {}) || [],
  }),

  computed: {
    // 項目セット
    computedFieldSet() {
      return this.fieldSet || {};
    },
    // 項目セットを整形したもの
    computedFields() {
      return (this.computedFieldSet.fields || []).map((v) => ({
        ...v,
        fieldInfo: this.fieldInfoList[v.fieldPath],
      }));
    },
  },

  watch: {
    targetObject: {
      handler(to) {
        this.$emit('original-object-updated', to);
        this.targetObjectUpdated();
      },
      deep: true,
    },
    targetFields(to) {
      this.$emit('original-target-updated', to);
      this.targetObjectUpdated();
    },
    // リセットの値が変更したらリセットをかける
    doReset: {
      handler() {
        this.reset();
      },
      deep: true,
    },
  },

  methods: {
    /********** 値のセットなど **********/
    // リセット
    async reset() {
      this.targetObject = _.mapValues(this.targetObject, () => null);
      await this.$nextTick();
      this.targetFields = [];
    },

    // 項目が変わった時
    async onInput(value, fieldPath) {
      if (!_.isNil(value)) {
        // nullじゃなければtrueにする
        this.targetFields.push(fieldPath);
      }
    },

    // 整形したオブジェクトの変更を通知する
    async targetObjectUpdated() {
      // targetFieldsにあるものだけを取り出してイベント通知
      let toEventObject = {};
      this.computedFields.map((field) => {
        // ここでtargetFieldsの物だけ抜き出し
        if (this.targetFields.includes(field.fieldPath)) {
          toEventObject[field.fieldPath] =
            this.targetObject[field.fieldPath] || null;
        }
      });
      this.$emit('input', toEventObject);
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
