<template>
  <v-data-table
    v-bind="list.dataTableProps"
    ref="listViewDataTable"
    v-model="list.selected"
    v-scroll="onScroll"
    :headers="list.displayHeaders"
    :items="list.listData"
    :item-key="list.uniqueKeyName"
    :item-class="
      (item) => {
        let classes = [];
        const errataType = item.ErrataType__c;
        if (errataType === '訂正') classes = [...classes, 'errata-revise'];
        if (errataType === '取消') classes = [...classes, 'errata-cancel'];
        return classes.join(' ');
      }
    "
    :show-select="computedShowCheckbox"
    :options.sync="list.options"
    :selectable-key="list.selectableKeyName"
    :hide-default-footer="list.editMode"
    :disable-sort="list.editMode"
    :mobile-breakpoint="0"
    :class="{
      'elevation-0': true,
      cdsTable: true,
      listView: true,
      dataTableEditMode: list.editMode,
      dataTableNotEditMode: !list.editMode,
      clickable: isRowClickable,
    }"
    @update:options="handleUpdateOptions"
    @click:row="handleClickRow"
  >
    <template #top>
      <div ref="listButtons">
        <TableMaxInformation v-if="applyMaxFields" />
        <ListButtons
          v-bind="listButtonProps"
          @toggle-list-edit-mode="handleToggleEditMode"
          @list-save="handleListSave"
          @reload-data="handleReloadData"
          @update:display-headers="handleUpdateDisplayHeaders"
        />
      </div>
    </template>

    <!-- チェックボックスの表示制御 -->
    <template #item.data-table-select="{ isSelected, select, item }">
      <v-simple-checkbox
        v-if="
          !_.has(item, list.selectableKeyName) || item[list.selectableKeyName]
        "
        color="green"
        :value="isSelected"
        :ripple="false"
        @input="select($event)"
      />
    </template>

    <!-- 各項目の表示を制御する -->
    <template
      v-for="header of list.displayHeaders"
      #[`item.${header.value}`]="props"
    >
      <Display
        :key="header.value"
        v-model="props.value"
        :item-props="props"
        :field-info="props.header.fieldInfo"
        :is-max-value="
          applyMaxFields && props.item.__maxFields.includes(header.value)
        "
      />
    </template>

    <!-- 添付ファイル項目 -->
    <template v-if="viewAttachment" #item.attachmentNum="props">
      <div v-if="props.item.attachmentNum" class="mr-2 ml-n2">
        <v-icon dense>
          mdi-paperclip
        </v-icon>
        <v-badge
          color="success"
          :content="props.item.attachmentNum"
          offset-x="0"
          offset-y="6"
        ></v-badge>
      </div>
    </template>
    <!-- コメント項目 -->
    <template v-if="viewComment" #item.commentNum="props">
      <div v-if="props.item.commentNum" class="mr-2 ml-n2">
        <v-icon dense class="mr-1">
          mdi-comment-text-multiple
        </v-icon>
        <v-badge
          color="success"
          :content="props.item.commentNum"
          offset-x="0"
          offset-y="6"
        ></v-badge>
      </div>
    </template>

    <!-- injectで指定されたコンポーネントを差し込む -->
    <template
      v-for="(com, comIndex) of itemSlotComponents"
      #[com.slotName]="props"
    >
      <component
        :is="com.componentName"
        v-bind="{
          ...props,
          ...forInjectComponentData,
          ...com.attr,
        }"
        :key="comIndex"
      ></component>
    </template>

    <!-- datatable内のスロットを上書きするスロット -->
    <!-- スロット名が重複した場合は、後述したスロットが使用される -->
    <template v-for="slotName of slots.datatable" #[slotName]="props">
      <slot :name="`datatable.${slotName}`" v-bind="props"></slot>
    </template>
  </v-data-table>
</template>

<script>
import ListButtons from './button/ListButtons.vue';
import TableMaxInformation from '../app/TableMaxInformation.vue';
import Display from './Display.vue';
import {
  getSlots,
  loadRecords,
  saveListData,
  applyFieldsToListData,
  applyListDataRow,
} from './util';
import { mapActions } from 'vuex';

export default {
  name: 'ListTable',
  components: {
    ListButtons,
    TableMaxInformation,
    Display,
  },
  inject: {
    listItem: {
      default: () => ({}),
    },
  },
  props: {
    /********** 共通 **********/
    // オブジェクト名
    objectName: { type: String, required: true },
    // オブジェクト情報
    objectInfo: { type: Object, required: true },
    // レコード取得メソッド名
    getRecordsMethodName: { type: String, default: 'getRecords' },
    // 一覧コンポーネント名(一意な英数字)
    listName: { type: String, default: null },
    // バージョン情報を持っているか
    hasVersion: { type: Boolean, default: false },

    /********** 検索 **********/
    // 検索条件
    searchData: { type: Object, default: () => ({}) },

    /********** ヘッダー **********/
    // 一覧項目セット名
    listFieldSetName: { type: String, default: 'ListFieldSet' },
    // 一覧項目セット名(初期表示)
    defaultListFieldSetName: { type: String, default: 'DefaultListFieldSet' },
    // 一覧項目セット名(inputモード)
    inputFieldSetName: { type: String, default: 'ListInputFieldSet' },
    // 一覧項目セット名(inputモード)(初期表示)
    defaultInputFieldSetName: {
      type: String,
      default: 'DefaultListInputFieldSet',
    },

    /********** リスト **********/
    // 表示モード
    le: { type: Boolean, default: undefined },
    // テーブルオプション
    defaultListOptions: { type: Object, default: () => ({}) },
    // 添付ファイルを表示させるか
    viewAttachment: { type: Boolean, default: false },
    // コメントを表示させるか
    viewComment: { type: Boolean, default: false },
    // 行クリック時の動作を独自実装する場合
    onClickFunc: { type: [Function, String], default: undefined },
    // 一覧編集時のバリデート(true or string)
    listSaveValidate: { type: Function, default: null },
    // injectするコンポーネントに渡すデータ
    forInjectComponentData: { type: Object, default: () => ({}) },
    // 一覧のプロパティに追加するもの
    customListProperty: { type: Object, default: () => ({}) },
    // リクエストのparamsに追加するもの
    paramsExtension: { type: Object, default: () => ({}) },
    // 最大値に色を付ける
    applyMaxFields: { type: Boolean, default: false },

    /********** 詳細 **********/
    // 詳細ページ名
    detailPageName: { type: String, default: null },

    /********** 新規作成 **********/
    // 新規作成させるか
    canCreate: { type: Boolean, default: true },
    // 新規作成ボタンの名称
    createButtonName: { type: String, default: '新規作成' },
    // 新規作成の遷移
    createPage: { type: Function, default: null },

    /********** 編集 **********/
    canEdit: { type: Boolean, default: true },

    /********** 確定 **********/
    canFix: { type: Boolean, default: true },

    /********** 削除 **********/
    canDelete: { type: Boolean, default: false },

    /********** 一括入力 **********/
    // 一括入力項目セット名
    bulkFieldSetName: {
      type: String,
      default: null,
    },

    /********** リロード **********/
    canReload: { type: Boolean, default: true },

    /********** CSV **********/
    // CSVファイル名フォーマット
    csvFileNameFormat: { type: String, default: null },
    // CSV項目セット名
    csvFieldSetName: { type: String, default: null },
    // ダウンロード可
    canCsvDownload: { type: Boolean, default: true },
    // CSV出力設定
    csvOutputConfig: {
      type: Object,
      default: () => ({}),
      /* sample
      {
        ThisIsCustomField__c: (value, fieldInfo, record) => {
          return value;
        }
      }
       */
    },

    /********** select **********/
    canSelectFields: { type: Boolean, default: true },

    /********** マスタ **********/
    // マスタオブジェクト名
    masterObjectName: { type: String, default: null },
    // マスタオブジェクト情報
    masterObjectInfo: { type: Object, default: () => ({}) },
    // マスタから動的オブジェクトにコピーしない項目
    noCopyField: { type: Array, default: null },
    // 動的情報のマスタIdが入っている項目名
    masterFieldName: { type: String, default: null },
  },
  data: (vm) => ({
    list: {
      // ユニークキー名
      uniqueKeyName: '___listViewUniqueKey',
      // 一覧編集モード
      editMode: false,
      // option
      options: {
        // 初期値
        page: 1,
        pageCount: 0,
        itemsPerPage: 50,
        // パラメータからのデフォルト値
        ...vm.defaultListOptions,
      },
      // テーブルに渡すプロパティ
      dataTableProps: {
        'server-items-length': 0,
        'footer-props': {
          showFirstLastPage: true,
          'items-per-page-options': [10, 20, 50, 100, 200],
          'items-per-page-all-text': 'すべて',
        },
      },
      // 表示する項目
      displayHeaders: [],
      // ページング
      pagination: {},
      // 一覧データ
      listData: [],
      // 一覧編集時の編集前データ
      listTempData: [],
      // 投げたリクエスト
      invokeRequest: null,
      // 選択データ
      selected: [],
      // 選択可能かどうかを判定するキー名
      selectableKeyName: '___isSelectable',
    },

    // datatableのitemスロットに動的に入れるコンポーネント
    itemSlotComponents: [],
    // テーブルヘッダ固定用
    stickyHeader: {
      top: 0,
      navbarHeight: 0,
    },
  }),
  computed: {
    // スロット
    slots() {
      return ['datatable'].reduce((prev, next) => {
        return {
          ...prev,
          [next]: getSlots(next, this.$slots, this.$scopedSlots),
        };
      }, {});
    },
    // 表示モード
    viewMode() {
      if (this.le !== undefined) {
        return this.le ? 'input' : 'fixed';
      }
      return null;
    },
    // ボタンに渡すプロパティ
    listButtonProps() {
      return {
        objectName: this.objectName,
        objectInfo: this.objectInfo,
        list: this.list,
        listName: this.listName,
        le: this.le,
        hasVersion: this.hasVersion,
        detailPageName: this.detailPageName,
        canCreate: this.canCreate,
        createButtonName: this.createButtonName,
        createPage: this.createPage,
        canEdit: this.canEdit,
        canFix: this.computedCanFix,
        canDelete: this.computedCanDelete,
        canReload: this.canReload,
        csvFileNameFormat: this.csvFileNameFormat,
        csvFieldSetName: this.csvFieldSetName,
        listFieldSetName: this.listFieldSetName,
        defaultListFieldSetName: this.defaultListFieldSetName,
        inputFieldSetName: this.inputFieldSetName,
        defaultInputFieldSetName: this.defaultInputFieldSetName,
        canCsvDownload: this.canCsvDownload,
        csvOutputConfig: this.csvOutputConfig,
        canSelectFields: this.canSelectFields,
        viewAttachment: this.viewAttachment,
        viewComment: this.viewComment,
        bulkFieldSetName: this.bulkFieldSetName,
      };
    },
    // 災害に紐づいているか
    isDisasterRelated() {
      return !!this.objectInfo.properties.CDS_T_Disaster__c;
    },
    // 災害Id
    disasterId() {
      return this.isDisasterRelated && this.$store.getters['disaster/exist']
        ? this.$store.state.disaster.disaster.Id
        : null;
    },
    // チェックボックスの表示
    computedShowCheckbox() {
      return !!(
        (this.list.editMode && this.bulkFieldSetName) ||
        (!this.list.editMode && this.computedCanFix) ||
        (!this.list.editMode && this.computedCanDelete)
      );
    },
    // 確定可能か
    computedCanFix() {
      return (
        this.hasVersion &&
        this.le === true &&
        this.canFix &&
        !!this.$store.state.user.user.Permission__c
      );
    },
    // 削除可能か
    computedCanDelete() {
      return this.canDelete && !!this.$store.state.user.user.Permission__c;
    },
    // クリック可能か
    isRowClickable() {
      return !!this.rowClickFunc;
    },
    rowClickFunc() {
      if (this.onClickFunc !== undefined) {
        if (typeof this.onClickFunc === 'function') {
          return async ({ item }) => {
            await this.onClickFunc(item, this);
          };
        } else {
          return null;
        }
      } else {
        return async ({ item, detail }) => {
          this.$emit('click-row', item, detail, this);
        };
      }
    },
  },

  watch: {
    searchData: {
      handler() {
        // 検索条件に変更があったら読み込み
        this.load();
      },
      deep: true,
    },
    'list.editMode': {
      handler(to) {
        this.$emit('update:editMode', to);
      },
    },
    'list.invokeRequest': {
      handler(to) {
        this.$emit('update:invoke-request', to);
      },
    },
  },

  async mounted() {
    await this.init();
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    async init() {
      // injectで指定されたコンポーネントの登録
      this.initializeInjectComponent();
    },

    /********** list **********/

    // 一覧のロード
    async load() {
      if (this.searchData) {
        await this.$store.dispatch('loading/register', this.loadListData());
      }
    },

    // 一覧データの読み込み
    async loadListData() {
      try {
        // 細かい処理は外部に丸投げ
        const { res, invokeRequest } = await loadRecords({
          controller: this.$pageProperty.controller,
          method: this.getRecordsMethodName,
          hasVersion: this.hasVersion,
          le: this.le,
          list: this.list,
          customListProperty: {
            ...this.customListProperty,
            baseDatetime: this.searchData?.baseDatetime,
            baseDatetimeFieldName: this.searchData?.baseDatetimeFieldName,
          },
          objectName: this.objectName,
          objectInfo: this.objectInfo,
          searchObject: this.searchData?.searchObject,
          disasterId: this.disasterId,
          masterObjectName: this.masterObjectName,
          masterObjectInfo: this.masterObjectInfo,
          noCopyField: this.noCopyField,
          masterFieldName: this.masterFieldName,
          paramsExtension: this.paramsExtension,
        });

        // 投げたリクエストを保持
        this.$set(this.list, 'invokeRequest', invokeRequest);

        if (res && res.records) {
          const { records, totalSize } = res;

          // 必要なプロパティを各recordに追加
          this.list.listData = await applyFieldsToListData({
            records,
            uniqueKeyName: this.list.uniqueKeyName,
            selectableKeyName: this.list.selectableKeyName,
            viewAttachment: this.viewAttachment,
            viewComment: this.viewComment,
            objectName: this.objectName,
            list: this.list,
            le: this.le,
            applyMaxFields: this.applyMaxFields,
            objectInfo: this.objectInfo,
          });
          this.list.dataTableProps['server-items-length'] = totalSize;

          this.$emit('load-completed', this.list);
        } else {
          // 取得したデータがない場合はcatchの処理を実行させたい
          throw new Error('recordsが取得できませんでした');
        }
      } catch (error) {
        this.list.listData = [];
        this.list.dataTableProps['server-items-length'] = 0;

        this.openSnackBar({
          message: 'データの取得に失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },

    // 一覧の保存
    async listSave() {
      try {
        await this.$store.dispatch(
          'loading/register',
          saveListData({
            objectName: this.objectName,
            objectInfo: this.objectInfo,
            listData: this.list.listData,
            listTempData: this.list.listTempData,
            controller: this.$pageProperty.controller,
            // leがtrueの時はバージョン情報があり確定フラグがあるため保存時にfalseにする
            fixedValue:
              this.hasVersion && this.le === true
                ? { IsFixed__c: false }
                : null,
            disasterId: this.disasterId,
            validate: this.listSaveValidate,
            store: this.$store,
          }),
        );
        this.saveComplete();
        // editModeを戻す
        this.handleToggleEditMode();
        // 表を読み込み直し
        await this.load();
      } catch (error) {
        this.saveFail(error.message);
        console.error(error);
      }
    },

    /********** list handler **********/

    // optionsが更新された時
    handleUpdateOptions() {
      // データ読み込み
      this.load();
    },

    // 表をクリックされた時
    async handleClickRow(item, detail) {
      const func = this.rowClickFunc;
      if (func) {
        await func({ item, detail });
      }
    },

    // editModeの反転
    async handleToggleEditMode() {
      this.list.editMode = !this.list.editMode;

      // 選択をクリア
      this.list.selected = [];

      if (this.list.editMode) {
        // 選択可能項目等の反映
        this.list.listData = applyListDataRow({
          records: this.list.listData,
          editMode: this.list.editMode,
          le: this.le,
          selectableKeyName: this.list.selectableKeyName,
        });

        // 一時データにコピー
        this.$set(
          this.list,
          'listTempData',
          structuredClone(this.list.listData),
        );
      } else {
        this.load();
      }
    },

    // 保存
    handleListSave() {
      this.listSave();
    },

    // 表のリロード
    handleReloadData() {
      this.load();
    },

    // 表示項目
    handleUpdateDisplayHeaders(v) {
      this.list.displayHeaders = v;
    },

    /********** inject **********/
    // injectで指定されたコンポーネントを設定
    initializeInjectComponent() {
      // 一覧の名前
      const listName = this.listName || 'default';
      // injectで指定されているか確認
      // slotName, componentをキーに持つオブジェクトの配列
      const componentDefinition = this.listItem[listName];
      if (!componentDefinition || !Array.isArray(componentDefinition)) return;

      // コンポーネント名を作成
      const componentInfo = componentDefinition.map(
        ({ slotName, component, ...attr }) => {
          return {
            slotName,
            component,
            // コンポーネント名を作成
            componentName: _.uniqueId(`${listName}-component_`),
            attr,
          };
        },
      );
      // コンポーネントの登録
      componentInfo.map((com) => {
        // コンポーネントの登録
        this.$options.components[com.componentName] = com.component;
      });
      // 表示用のコンポーネントリストを作成しセット
      this.itemSlotComponents = componentInfo.map(
        ({ slotName, componentName, attr }) => ({
          slotName,
          componentName,
          attr,
        }),
      );
    },

    /********** スクロール **********/
    onScroll() {
      // $elがなければおわり
      if (!this.$refs.listViewDataTable) return;
      let headerTop = 0;
      this.setDatatablePosition();
      // テーブルヘッダがスクロールに追従するようにする
      if (
        this.stickyHeader.navbarHeight + window.scrollY >
        this.stickyHeader.top
      ) {
        headerTop =
          this.stickyHeader.navbarHeight +
          window.scrollY -
          this.stickyHeader.top;
      }
      let thead = this.$refs.listViewDataTable.$el.querySelector(
        '.v-data-table__wrapper thead.v-data-table-header',
      );
      thead.style.setProperty('top', headerTop + 'px');
      // スクロールが一覧表を超えたらヘッダ固定を解除
      if (
        headerTop >
        this.$refs.listViewDataTable.$el.querySelector(
          '.v-data-table__wrapper table',
        ).clientHeight
      ) {
        thead.style.setProperty('top', '0px');
      }
    },
    handleResize() {
      this.setDatatablePosition();
    },
    setDatatablePosition() {
      const table = this.$refs.listViewDataTable.$el;
      // ヘッダ固定を開始する位置を取得する
      // テーブル左上位置 + テーブル上のボタンエリア高さ + スクロール量
      this.stickyHeader.top =
        table.getBoundingClientRect().top +
        this.$refs.listButtons.clientHeight +
        window.scrollY;
      if (document.getElementById('dis-app-bar') !== null) {
        this.stickyHeader.navbarHeight = document.getElementById(
          'dis-app-bar',
        ).clientHeight;
      }
    },

    /********** その他 **********/
    ...mapActions('snackbar', ['saveComplete', 'saveFail', 'openSnackBar']),
  },
};
</script>

<style lang="scss">
.dataTableEditMode .v-data-table__wrapper {
  border: 2px solid #e53935;
}
.cdsTable.listView .v-data-table__wrapper {
  overflow-x: auto;
}
.v-tabs-items > div.v-window__container {
  height: 100%;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
  padding-right: 8px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  white-space: nowrap;
  overflow: hidden;
}
.v-data-table__wrapper thead.v-data-table-header {
  position: sticky;
  z-index: 1;
}

.v-tabs .v-window,
.v-tabs .v-window-item,
.v-tabs .v-card,
.v-tabs .v-card__text {
  height: 100%;
}
</style>
