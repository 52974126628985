<template>
  <div class="fill-height pt-4 listView" :class="{ hideTab }">
    <v-tabs
      v-model="tab"
      class="fill-height cdsTabs cds-list-flex-tabs d-flex flex-column"
    >
      <!-- 検索ボタン -->
      <slot name="search">
        <Search
          v-bind="computedSearchProps"
          class="searchIcon"
          @search="handleSearch"
        />
      </slot>
      <v-tab class="listTab" href="#list-table">
        <v-icon left>
          mdi-table
        </v-icon>
        一覧
      </v-tab>
      <v-tab v-if="showImageTab" href="#list-image">
        <v-icon left>
          mdi-image-multiple
        </v-icon>
        画像/動画
      </v-tab>
      <v-tab v-if="showMapTab" href="#list-googlemap">
        <v-icon left>
          mdi-google-maps
        </v-icon>
        地図
      </v-tab>
      <!-- tab追加用 -->
      <v-tab
        v-for="slotName of slots.tab"
        :key="slotName"
        :disabled="list.editMode"
      >
        <slot :name="`tab.${slotName}`" v-bind="{ list, search }"></slot>
      </v-tab>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item class="fill-height" eager value="list-table">
          <ListTable
            v-if="Object.keys(objectInfo).length > 0"
            v-bind="computedTableProps"
            @update:editMode="handleUpdateEditMode"
            @update:invoke-request="handleUpdateInvokeRequest"
            @click-row="handleClickRow"
            @load-completed="$emit('load-completed', $event)"
          >
            <template
              v-for="slotName of slots.datatable"
              #[`datatable.${slotName}`]="props"
            >
              <slot :name="`datatable.${slotName}`" v-bind="props"></slot>
            </template>
          </ListTable>
        </v-tab-item>
        <v-tab-item
          v-if="showImageTab"
          class="fill-height"
          eager
          value="list-image"
        >
          <v-card flat class="fill-height">
            <ListImage
              v-if="Object.keys(objectInfo).length > 0"
              v-bind="computedListImageProps"
              @to-detail="handleClickRow"
            >
              <template v-for="slotName of slots.listimage" #[slotName]="props">
                <slot :name="`listimage.${slotName}`" v-bind="props"></slot>
              </template>
            </ListImage>
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-if="showMapTab"
          class="fill-height"
          eager
          value="list-googlemap"
        >
          <div class="fill-height pt-2">
            <GoogleMap
              v-if="Object.keys(objectInfo).length > 0"
              v-bind="computedGoogleMapProps"
              :style="{ height: '100%' }"
            />
          </div>
        </v-tab-item>
        <!-- tab追加用 -->
        <v-tab-item
          v-for="slotName of slots.tabitem"
          :key="slotName"
          class="fill-height"
        >
          <slot :name="`tabitem.${slotName}`" v-bind="{ list, search }"></slot>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <!-- 一覧編集 -->
    <Detail
      v-if="detailLayoutName"
      v-model="detail"
      :object-name="objectName"
      :object-info="objectInfo"
      :detail-layout-name="detailLayoutName"
    />
  </div>
</template>

<script>
import { getSlots } from './util';
import Detail from './Detail.vue';
import Search from './search/index.vue';
import ListTable from './ListTable.vue';
import ListImage from './ListImage.vue';
import GoogleMap from './GoogleMap.vue';

export default {
  name: 'ListView',
  components: {
    Search,
    Detail,
    ListTable,
    ListImage,
    GoogleMap,
  },
  props: {
    /********** 共通 **********/
    // オブジェクト名
    objectName: { type: String, required: true },
    // 災害関連
    isDisasterRelated: { type: Boolean, default: false },
    // 一覧コンポーネント名(一意な英数字)
    listName: { type: String, default: null },
    // バージョン情報を持っているか
    hasVersion: { type: Boolean, default: false },
    // マスタオブジェクト名
    masterObjectName: { type: String, default: null },

    /********** tab **********/
    // 画像タブを表示するか
    showImageTab: { type: Boolean, default: false },
    // 地図タブを表示するか
    showMapTab: { type: Boolean, default: false },

    /********** 詳細 **********/
    // 詳細ページ名
    detailPageName: { type: String, default: null },
    // 詳細編集レイアウト名
    detailLayoutName: { type: String, default: null },

    /********** 一覧 **********/
    // テーブルに渡すプロパティ
    tableProps: { type: Object, default: () => ({}) },
    /********** 検索 **********/
    // 検索に渡すプロパティ
    searchProps: { type: Object, default: () => ({}) },
    /********** GoogleMap **********/
    // GoogleMapに渡すプロパティ
    googleMapProps: { type: Object, default: () => ({}) },
    // 詳細画面の履歴一覧で不要なタブを非表示にするためのプロパティ
    hideTab: { type: Boolean, default: false },
  },
  data: () => ({
    tab: null,

    objectInfo: {},
    masterObjectInfo: {},

    // 検索
    search: null,

    // リスト
    list: {
      editMode: false,
      invokeRequest: null,
    },

    // 詳細
    detail: {
      dialog: false,
      item: null,
    },
  }),
  computed: {
    // listEdit
    le() {
      return this.hasVersion ? this.$query.current().le === 'true' : undefined;
    },

    // テーブルに渡すプロパティ
    computedTableProps() {
      return {
        ...this.tableProps,
        objectName: this.objectName,
        objectInfo: this.objectInfo,
        masterObjectInfo: this.masterObjectInfo,
        searchData: this.search,
        detailPageName: this.detailPageName,
        listName: this.listName,
        le: this.le,
        hasVersion: this.hasVersion,
        masterObjectName: this.masterObjectName,
      };
    },
    // 検索に渡すプロパティ
    computedSearchProps() {
      return {
        ...this.searchProps,
        objectName: this.objectName,
        disabled: this.list.editMode,
        listName: this.listName,
        le: this.le,
      };
    },
    // imageに渡すプロパティ
    computedListImageProps() {
      return {
        objectName: this.objectName,
        invokeRequest: this.list.invokeRequest,
        isActive: this.tab === 'list-image',
      };
    },
    // GoogleMapに渡すプロパティ
    computedGoogleMapProps() {
      return {
        ...this.googleMapProps,
        objectName: this.objectName,
        invokeRequest: this.list.invokeRequest,
        isActive: this.tab === 'list-googlemap',
      };
    },

    // スロット
    slots() {
      return ['tab', 'tabitem', 'datatable', 'listimage'].reduce(
        (prev, next) => {
          return {
            ...prev,
            [next]: getSlots(next, this.$slots, this.$scopedSlots),
          };
        },
        {},
      );
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.loadObjectInfo();
    },

    /********** 初期 **********/
    async loadObjectInfo() {
      const [objectInfo, masterObjectInfo] = await Promise.all(
        [this.objectName, this.masterObjectName].map(async (name) => {
          if (!name) return {};
          return this.$util.getObjectInfo(name);
        }),
      );
      this.$set(this, 'objectInfo', objectInfo);
      this.$set(this, 'masterObjectInfo', masterObjectInfo);
    },

    /********** 検索 **********/
    // 検索ボタンが押された時
    async handleSearch(searchData) {
      this.$set(this, 'search', searchData);
    },

    /********** 共通 **********/

    // editModeが変更された時
    handleUpdateEditMode(editMode) {
      this.list.editMode = editMode;
    },

    // 行がクリックされた時
    handleClickRow(item) {
      if (this.list.editMode) {
        // 一覧編集機能は詳細画面と同様の入力チェックや地図機能を実装することが難しく
        // 適切な入力チェックが無い状態で機能を有効にするとデータが壊れたり、
        // 将来的な改修で詳細画面側の機能と差異が発生する懸念があるため利用しないこととした
        // this.$set(this.detail, 'item', item);
        // this.$set(this.detail, 'dialog', true);
      } else {
        this.toDetail(item, {
          masterObjectName: this.masterObjectName,
          masterFieldName: this.tableProps.masterFieldName,
        });
      }
    },

    // リクエストが更新された時
    handleUpdateInvokeRequest(invokeRequest) {
      this.$set(this.list, 'invokeRequest', invokeRequest);
    },

    /********** 詳細 **********/

    // 詳細に遷移
    toDetail(item, { masterObjectName, masterFieldName }) {
      if (item.Id) {
        this.transitionDetailPage({
          id: item.Id,
        });
      } else if (masterObjectName && item[masterFieldName]) {
        // マスタIDがある場合
        this.transitionDetailPage({
          mid: item[masterFieldName],
        });
      }
    },

    // 詳細ページに移動
    transitionDetailPage(options) {
      if (this.detailPageName) {
        this.$transition.to(this.detailPageName, options);
      }
    },
  },
};
</script>

<style lang="scss">
.listView.hideTab .v-tabs-bar {
  display: none;
}
</style>
