<template>
  <div ref="map" class="d-flex" :style="{ height: '500px' }" />
</template>

<script>
import mapLoader from '@/googlemap/loader';
import * as dataDrawUtil from '@/googlemap/dataDrawUtil';
import mdi from '@/assets/mdiIcons';
import { getUrl } from '@/assets/js/s3';

export default {
  name: 'ListGoogleMap',
  props: {
    // オブジェクト名
    objectName: {
      type: String,
      required: true,
    },
    // リクエスト
    invokeRequest: {
      type: Object,
      default: null,
    },
    // 動的表示条件
    gisDynamicSettingName: {
      type: String,
      required: true,
    },
    // 表示中
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    // idの配列
    targetIds: [],
    // 添付ファイルのrawデータ
    objects: [],
    map: null,
    polygons: [],
    // 読み込みに使った最後のinvokeRequest
    lastInvokeRequest: null,

    // map: null,
    // gisSettingData: null,
    // fieldSet: null,
    // conditions: null,
  }),

  watch: {
    isActive(to, from) {
      if (to && !from) {
        if (!_.isEqual(this.invokeRequest, this.lastInvokeRequest)) {
          this.$store.dispatch('loading/register', this.init());
        }
      }
    },
  },

  async mounted() {
    await this.loadMap();
  },

  methods: {
    async init() {
      await this.loadMap();
      await this.loadRecordIds();
      await this.loadAttachment();
      await this.drawData();
    },

    /**
     * マップの初期化
     */
    async loadMap() {
      const loader = await mapLoader();
      this.map = this.map ?? loader.createMap(this.$refs.map);
      // 設定データの読み込み
      const [gisSettingData] = await this.$con.invoke({
        controller: 'CDS_CTR_Common',
        method: 'getDynamicMetaData',
        params: this.gisDynamicSettingName,
      });
      this.gisSettingData = gisSettingData;
      const { fieldSet, objectInfo } = await this.$util.getFieldSet(
        this.gisSettingData.TargetObject__c,
        this.gisSettingData.ItemSetName__c,
      );
      this.fieldSet = fieldSet;
      this.objectInfo = objectInfo;
      this.conditions = this.gisSettingData.conditions;
    },

    /**
     * 検索オブジェクトを利用してすべてのidのリストを取得する
     */
    async loadRecordIds() {
      if (!this.invokeRequest) return;

      const request = structuredClone(this.invokeRequest);

      // GISのデータは容量が多いことが予想されるので再帰処理する
      // 結果
      let result = [];
      // 続けるか
      let next = true;

      // ページ番号
      request.params.listOptions.page = 0;
      // 1ページあたりの表示件数
      request.params.listOptions.itemsPerPage = 50;

      do {
        // ページを追加
        request.params.listOptions.page = request.params.listOptions.page + 1;

        // データ取得
        const res = await this.$con.invoke(request);
        const { records } = res;
        result = [...result, ...records];
        // 0件じゃなかったら再度取得
        next = records.length !== 0;
      } while (next);

      // リクエストを保持
      this.lastInvokeRequest = structuredClone(this.invokeRequest);

      // 何もデータがない場合は処理を終わる
      if (result.length === 0) {
        this.objects = [];
      } else {
        this.objects = result;
      }
    },

    /**
     * 添付ファイルのデータを取得する
     */
    async loadAttachment() {
      if (this.objects.length === 0) {
        return;
      }
      // GISデータが項目に入っている場合は整形して終了
      if (this.gisSettingData.PlaceField__c) {
        this.objects = this.objects
          .map((obj) => {
            // 位置情報や図形の項目
            let locationField = obj[this.gisSettingData.PlaceField__c]?.trim();
            // 中身があれば整形
            if (locationField) {
              try {
                const content = JSON.parse(locationField);
                // 緯度経度の場合はデータの形を変える
                if (
                  content.latitude !== undefined &&
                  content.longitude !== undefined
                ) {
                  const marker = new google.maps.Marker({
                    position: { lat: content.latitude, lng: content.longitude },
                  });
                  locationField = JSON.stringify({
                    features: [marker.getJSON()],
                  });
                }
                // それ以外の図形情報はそのままにする
              } catch (error) {
                console.log('GISデータの読み込みに失敗しました。');
              }
            }
            return {
              ...obj,
              __gisdata: locationField,
            };
          })
          .filter((obj) => !!obj.__gisdata);
        return;
      }
      // GISデータが添付ファイルの場合は読み込み
      else {
        const res =
          (await this.$store.dispatch(
            'loading/register',
            this.$con.invoke({
              controller: 'CDS_CTR_Common',
              method: 'getAttachment',
              params: {
                objectName: this.objectName,
                ids: JSON.stringify(
                  this.objects.map((o) => o.Id).filter((v) => !!v),
                ),
              },
            }),
          )) || [];
        if (!Array.isArray(res)) {
          this.objects = [];
          return;
        }
        // resをチャンクにして投げる
        const resChunk = _.chunk(res, 20);
        for (const chunk of resChunk) {
          await Promise.all(
            chunk.map(async (d) => {
              try {
                // GIS用GEOJSONを読み出す
                const { attachments } = d;
                if (!attachments) return;
                const { FileId } =
                  attachments.find(({ Name }) =>
                    Name.match(/gis_(.*)_data.json/),
                  ) || {};
                if (!FileId) return;
                const url = await getUrl(FileId);
                d.__gisdata = await fetch(url).then((res) => res.text());
              } catch (error) {
                console.error('GISデータの呼び出しに失敗しました。', error);
              }
            }),
          );
        }
        this.objects = res;
      }
    },

    /**
     * データを描画する
     */
    async drawData() {
      const { gisSettingData, fieldSet, objectInfo, conditions } = this;
      // 描画済みデータ初期化
      this.polygons.forEach(({ overlay, type }) => {
        overlay.setMap(null);
        if (type === 'culuster') {
          overlay.clearMarkers();
        }
      });
      this.polygons = [];
      await Promise.all(
        this.objects.map(async (d) => {
          // 合致する表示条件を読み出し
          const conditionMetadata = conditions?.find(({ Condition__c }) =>
            Function('obj', `return ${Condition__c}`)(d),
          );
          if (!conditionMetadata) return;
          // 描画色設定
          let color = conditionMetadata.Color__c;
          if (color.charAt(0) !== '#') {
            color = Function('obj', `return ${color}`)(d);
          }
          // アイコン設定
          const path = mdi[conditionMetadata.Icon__c];
          // 図形描画
          const polygons = dataDrawUtil.createPolygons({
            map: this.map,
            color,
            json: d.__gisdata,
            path,
          });
          // ポップアップウィンドウ表示
          const url = gisSettingData.URL__c
            ? gisSettingData.URL__c.replace(/\{id\}/, d.Id)
            : '';
          dataDrawUtil.createPopupWindow(polygons, {
            title: gisSettingData.Label,
            icon: mdi[conditionMetadata.Icon__c],
            fieldSet,
            objectInfo,
            color,
            url,
            object: d,
          });
          this.polygons = [...this.polygons, ...polygons];
        }),
      );
      // マーカークラスタリング
      const culuster = dataDrawUtil.markerClustering(this.map, this.polygons);
      this.polygons.push({ type: 'culuster', overlay: culuster });
    },
  },
};
</script>
