<template>
  <v-btn
    v-if="!list.editMode && computedCanEdit && !$vuetify.breakpoint.xs"
    depressed
    class="mr-3"
    @click="$emit('toggle-list-edit-mode')"
  >
    <v-icon left>
      mdi-table-edit
    </v-icon>
    <span>一覧編集</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    list: { type: Object, default: () => ({}) },
    le: { type: Boolean, default: undefined },
    canEdit: { type: Boolean, default: true },
  },
  data: () => ({}),
  computed: {
    computedCanEdit() {
      return (
        this.le !== false &&
        !!this.$store.state.user.user.Permission__c &&
        (this.canEdit === null
          ? this.$store.state.user.organization.Name == '本部'
          : this.canEdit)
      );
    },
  },
};
</script>
