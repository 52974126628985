<template>
  <form-section
    v-bind="{
      title: section.label,
      hidden,
      notShow,
      editMode,
    }"
  >
    <template #bottom_title>
      <slot name="bottom_title" />
    </template>
    <v-col>
      <template v-for="item of section.items">
        <div :key="item.fieldPath" class="d-flex align-center">
          <div class="flex-grow-0 flex-shrink-0">
            <v-checkbox
              v-model="editValue"
              hide-details
              class="ma-0 pa-0"
              :value="item.fieldPath"
            />
          </div>
          <div class="flex-grow-1 flex-shrink-1">
            <!-- injectで置き換えの指定があればそれを表示 -->
            <component
              :is="itemSlotComponents[item.fieldPath].componentName"
              v-if="Object.keys(itemSlotComponents).includes(item.fieldPath)"
              :key="item.fieldPath"
              v-bind="{
                section,
                item,
                editMode,
                hidden,
                readonly,
                customValidations,
                controller,
              }"
              @input="handleValueChanged($event, item)"
            />
            <!-- 特に指定がなければデフォルトのfieldを表示 -->
            <field
              v-else
              :hidden="hiddenFieldNames.indexOf(item.fieldPath) > -1"
              :edit-mode="editMode"
              :readonly="
                readonly || readonlyFieldNames.includes(item.fieldPath)
              "
              :field-info="fieldInfoList[item.fieldPath]"
              :field-path="item.fieldPath"
              :column="section.column"
              :custom-validation="
                customValidations.find((v) => v.fieldName === item.fieldPath) ||
                null
              "
              :input-props="fieldInputProps[item.fieldPath]"
              @input="handleValueChanged($event, item)"
            >
            </field>
          </div>
        </div>
      </template>
    </v-col>
    <template #bottom_row>
      <slot name="bottom_row" />
    </template>
  </form-section>
</template>

<script>
import FormSection from '@/components/detail/section';
import field from '@/components/detail/field';

export default {
  name: 'SearchSection',
  components: {
    field,
    FormSection,
  },

  inject: {
    searchItem: {
      default: () => ({}),
    },
  },

  props: {
    value: { type: Array, default: null },
    // 一覧コンポーネント名(一意な英数字)
    listName: { type: String, default: null },
    section: { type: Object, default: null },
    fieldInfoList: { type: Object, default: null },
    hidden: { type: Boolean, required: false, default: () => false },
    hiddenFieldNames: { type: Array, required: false, default: () => [] },
    readonly: { type: Boolean, required: false, default: false },
    readonlyFieldNames: { type: Array, required: false, default: () => [] },
    customValidations: { type: Array, required: false, default: () => [] },
    notShow: { type: Boolean, required: false, default: () => false },
    controller: { type: String, default: null },
    editMode: Boolean,
    // field内のFormulateInputに橋渡しするProp
    fieldInputProps: {
      type: Object,
      default: () => ({}),
    },
    condition: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    itemSlotComponents: {},
  }),

  computed: {
    // 選択
    editValue: {
      get() {
        return this.value || [];
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    // 値が変更された時
    handleValueChanged(event, item) {
      // 中身が空でなければ選択にする
      const { value } = event;
      const { fieldPath } = item;
      const fieldInfo = this.fieldInfoList[fieldPath];
      if (!this.isEmpty(value)) {
        if (fieldInfo.type === 'boolean') {
          const beforeValue = this.condition[fieldPath];
          // 前回値がundefinedで今回値がfalseの場合は人間が選択変更してないのでスルー
          if (beforeValue === undefined && value === false) {
            return;
          }
        }
        if (!this.editValue.includes(fieldPath)) {
          this.editValue = [...this.editValue, fieldPath];
        }
      }
    },

    isEmpty(value) {
      try {
        // 空白ならok
        if (_.isNil(value)) return true;
        // 配列でかつ空ならok
        if (Array.isArray(value)) {
          if (value.length === 0) return true;
        }
        // 配列でないオブジェクトでキーがなければok
        else if (_.isObjectLike(value)) {
          if (Object.keys(value).length === 0) return true;
        }
      } catch (ignored) {
        //
      }
      return false;
    },

    /********** inject **********/
    // injectで指定されたコンポーネントを設定
    initializeInjectComponent() {
      // 一覧の名前
      const listName = this.listName || 'default';
      // injectで指定されているか確認
      // slotName, componentをキーに持つオブジェクトの配列
      const componentDefinition = this.searchItem[listName];
      if (!componentDefinition || !Array.isArray(componentDefinition)) return;

      // コンポーネント名を作成
      const componentInfo = componentDefinition.map(
        ({ fieldPath, component }) => {
          return {
            fieldPath,
            component,
            // コンポーネント名を作成
            componentName: _.uniqueId(`${listName}-searchcomponent_`),
          };
        },
      );
      // コンポーネントの登録
      componentInfo.map((com) => {
        // コンポーネントの登録
        this.$options.components[com.componentName] = com.component;
      });
      // 表示用のコンポーネントリストを作成しセット
      this.$set(
        this,
        'itemSlotComponents',
        componentInfo.reduce((prev, { fieldPath, componentName }) => {
          return {
            ...prev,
            [fieldPath]: componentName,
          };
        }, {}),
      );
    },
  },
};
</script>
