<template>
  <Confirm
    v-if="!list.editMode && canDelete && !$vuetify.breakpoint.xs"
    message="削除します。よろしいですか？"
    @on-click-yes="callDelete"
  >
    <template #default="{ on }">
      <v-btn
        depressed
        class="mr-3"
        :disabled="disabled"
        color="primary"
        v-on="on"
      >
        <v-icon left>
          mdi-table-check
        </v-icon>
        <span>削除</span>
      </v-btn>
    </template>
  </Confirm>
</template>

<script>
import Confirm from '@/components/common/Confirm';
import { deleteRecords } from '../util';
import { mapActions } from 'vuex';

export default {
  name: 'Delete',
  components: {
    Confirm,
  },

  props: {
    // オブジェクト名
    objectName: { type: String, required: true },
    list: { type: Object, default: () => ({}) },
    canDelete: { type: Boolean, default: false },
  },

  computed: {
    disabled() {
      return !this.canDelete || this.list.selected.length === 0;
    },
  },

  methods: {
    async callDelete() {
      await this.$store.dispatch('loading/register', this.executeDelete());
    },
    async executeDelete() {
      try {
        await deleteRecords({
          records: this.list.selected,
          objectName: this.objectName,
          controller: this.$pageProperty.controller,
        });
        // eslint-disable-next-line vue/no-mutating-props
        this.list.selected = [];
        // リロード
        this.$emit('reload-data');
        // 確定
        this.saveComplete('削除しました。');
      } catch (error) {
        this.openSnackBar({
          message: '削除に失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },

    ...mapActions('snackbar', ['openSnackBar', 'saveComplete']),
  },
};
</script>
