<template>
  <!-- 検索ボタン -->
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        v-bind="{ on, attrs, disabled: computedDisabled, openDialog }"
      >
        <v-badge
          :value="button.hasSearchCondition"
          color="pink"
          dot
          overlap
          class="mt-3 ml-1 mr-3"
        >
          <v-btn
            color="primary"
            icon
            small
            v-bind="attrs"
            :disabled="computedDisabled"
            v-on="on"
            @click="openDialog"
          >
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </v-badge>
      </slot>
      <v-dialog v-model="search.show" scrollable max-width="900px">
        <v-card v-if="search.layout">
          <v-card-title>
            <span class="text-h5">{{ execButtonName }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-card elevation="0" class="listSearchCard">
              <v-card-text :class="[$vuetify.breakpoint.mobile ? 'px-0' : '']">
                <template v-if="showBaseDatetime">
                  <v-row class="px-3 py-4">
                    <v-col cols="12">
                      <DateTimeInput :context="search.baseDatetime.context" />
                    </v-col>
                  </v-row>
                  <hr />
                </template>
                <FormulateForm
                  v-model="search.condition"
                  name="search_form"
                  @submit-raw="(form) => validate(form)"
                >
                  <SearchSection
                    v-for="section in search.layout.sections"
                    :key="section.id"
                    v-model="search.formSelected"
                    :section="section"
                    :field-info-list="search.objectInfo.properties"
                    :edit-mode="true"
                    :list-name="listName"
                    :condition="search.condition"
                  >
                    <template #bottom_title>
                      <slot :name="section.label"></slot>
                    </template>
                    <template #bottom_row>
                      <slot :name="section.label + '_bottom'"></slot>
                    </template>
                  </SearchSection>
                </FormulateForm>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="resetDialog">
              クリア
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">
              閉じる
            </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="$formulate.submit('search_form')"
            >
              {{ execButtonName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <span>{{ execButtonName }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';
import SearchSection from './SearchSection.vue';
import DateTimeInput from '@/components/inputs/date-time';
import { convertObjectInfoForSearch, generateSearchObject } from './util';

export default {
  name: 'Search',
  components: {
    SearchSection,
    DateTimeInput,
  },

  props: {
    /**
     * 共通
     */
    // オブジェクト名
    objectName: { type: String, required: true },
    // 一覧コンポーネント名(一意な英数字)
    listName: { type: String, default: null },
    // 無効
    disabled: { type: Boolean, default: false },
    // レイアウト名
    layoutName: { type: String, default: 'SearchLayout' },
    // 表示モード
    le: { type: Boolean, default: undefined },
    // オブジェクト情報の変更
    changeObjectInfo: { type: Function, default: null },
    // オブジェクト情報の変更
    changeLayout: { type: Function, default: null },
    /**
     * 条件
     */
    // 検索可能
    canSearch: { type: Boolean, default: true },
    // 検索条件デフォルト
    defaultSearchCondition: { type: [Object, Function], default: () => ({}) },
    // 強制的に付与する条件
    forceCondition: { type: [Object, Function], default: () => ({}) },
    // 検索条件に訂正取消を自動指定するか
    applyErrataToCondition: { type: Boolean, default: false },
    // URLパラメータと検索条件を同期するか
    syncConditionWithUrl: { type: Boolean, default: false },
    /**
     * 検索
     */
    // フィルタボタン名
    execButtonName: { type: String, default: 'フィルタ' },
    /**
     * 基準日時
     */
    // 基準日時の表示
    showBaseDatetime: { type: Boolean, default: false },
    // 基準日時項目
    baseDatetimeFieldName: { type: String, default: 'CreatedDate' },
    // 初期値
    baseDatetimeDefaultValue: { type: String, default: null },
  },

  data: () => ({
    // ボタン
    button: {
      // 検索条件あり
      hasSearchCondition: false,
    },

    // ダイアログ
    search: {
      // 表示
      show: false,
      // 条件
      condition: {},
      // 条件一時保管
      conditionTemp: {},
      // レイアウト
      layout: {},
      // オブジェクト情報
      objectInfo: {},

      // 選択済み項目
      formSelected: [],
      formSelectedTemp: [],

      // 基準日時
      baseDatetime: {
        context: {
          model: null,
          attributes: {},
          label: '基準日時',
          name: 'BaseDatetime--search',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          blurHandler: () => {},
        },
        modelTemp: null,
      },
    },
  }),

  computed: {
    // 無効
    computedDisabled() {
      // 無効の指定がある場合とレイアウトがない場合
      return this.disabled || !this.search.layout || !this.canSearch;
    },
  },

  async mounted() {
    await this.$store.dispatch('loading/register', this.init());
  },

  methods: {
    /**
     * 初期処理
     */

    // 初期ロード
    async init() {
      // レイアウトの読み込み
      await this.loadLayout();
      // 初期ロード時の検索条件の指定
      await this.setInitialSearchCondition();
      // データ取り出し
      await this.callSearch('mounted');
    },

    // レイアウトの読み込み
    async loadLayout() {
      const { layoutName, objectName } = this;
      const {
        layout: originLayout,
        objectInfo: originObjectInfo,
      } = await this.$util.getLayout(objectName, layoutName);

      // 変換の必要があれば実施
      const layout =
        (await this.changeLayout?.({
          layout: structuredClone(originLayout),
        })) || originLayout;

      // 一覧のオブジェクトと被るとややこしいのでクローンしてから変換をかける
      const objectInfo = this.convertObjectInfo(
        (await this.changeObjectInfo?.({
          objectInfo: structuredClone(originObjectInfo),
        })) || structuredClone(originObjectInfo),
      );

      this.$set(this.search, 'layout', layout);
      this.$set(this.search, 'objectInfo', objectInfo);
    },

    // オブジェクト情報の変換
    convertObjectInfo(objectInfo) {
      return convertObjectInfoForSearch({
        objectInfo,
      });
    },

    // 初期ロード時の検索条件の設定
    async setInitialSearchCondition() {
      let resultCondition = {
        ...this.search.condition,
      };
      let resultBaseDatetime = this.baseDatetimeDefaultValue;

      // デフォルトの検索条件
      // 検索条件がfunctionだった場合にそなえる
      const defaultSearchCondition =
        typeof this.defaultSearchCondition === 'function'
          ? await this.defaultSearchCondition()
          : this.defaultSearchCondition;
      if (Object.keys(defaultSearchCondition).length !== 0) {
        resultCondition = {
          ...resultCondition,
          ...defaultSearchCondition,
        };
      }

      // URLから検索条件を設定
      if (this.syncConditionWithUrl) {
        resultCondition = {
          ...resultCondition,
          ...JSON.parse(this.$query.current().search || '{}'),
        };
        if (this.showBaseDatetime) {
          resultBaseDatetime = this.$query.current().bdt || null;
        }
      }

      // 訂正取消条件の付与
      if (this.applyErrataToCondition) {
        // 条件のキーがなければ、全件選択
        if (!resultCondition.ErrataType__c) {
          resultCondition = {
            ...resultCondition,
            ErrataType__c: [null, '訂正', '取消'],
          };
        }
      }

      // フォームに値を設定
      this.$set(this.search, 'condition', {
        ...resultCondition,
      });
      this.$set(this.search.baseDatetime.context, 'model', resultBaseDatetime);

      // キーを選択する
      this.search.formSelected = [
        ...new Set([
          ...this.search.formSelected,
          ...Object.keys(resultCondition),
        ]),
      ];
    },

    /**
     * フォーム
     */

    // バリデート
    async validate(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }
        this.callSearch('submit');
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    /**
     * 検索処理
     */

    // 検索
    async callSearch(callType) {
      // データ取り出し
      try {
        await this.executeSearch(callType);
      } catch (error) {
        this.openSnackBar({
          message: 'データのロードに失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },

    // 検索オブジェクトを作成してイベント通知
    async executeSearch(callType) {
      // 基準日時
      const baseDatetime = this.showBaseDatetime
        ? this.search.baseDatetime.context.model
        : null;

      // 検索オブジェクトを生成
      const {
        searchObject,
        targetCondition,
        hasCondition,
      } = await this.generateSearchObject();

      // URLパラメータに設定
      if (this.syncConditionWithUrl) {
        // 検索条件を指定
        const query = {
          ...this.$query.current(),
          search: hasCondition ? JSON.stringify(targetCondition) : undefined,
          bdt: baseDatetime || undefined,
        };

        // パラメータに設定
        this.$query.updateHistoryQuery({
          query,
        });
      }

      // 検索条件を持っているか
      const hasConditionFinally = hasCondition || !!baseDatetime;

      // 検索条件を持っている場合はtrueになる
      this.button.hasSearchCondition = hasConditionFinally;

      await this.$nextTick();

      // イベント通知
      this.$emit('search', {
        callType,
        searchObject,
        targetCondition,
        hasCondition: hasConditionFinally,
        baseDatetime,
        baseDatetimeFieldName: this.baseDatetimeFieldName,
      });

      // ダイアログを閉じる
      this.closeDialog();
    },

    // 検索オブジェクトの生成
    async generateSearchObject() {
      // 検索オブジェクトの生成
      const result = await generateSearchObject({
        objectInfo: this.search.objectInfo,
        condition: this.search.condition,
        selectedFields: this.search.formSelected,
        forceCondition: this.forceCondition,
        le: this.le,
      });

      // 検索オブジェクトを返却
      return result;
    },

    /**
     * ダイアログ
     */

    // ダイアログを開く
    openDialog() {
      this.search.show = true;
      this.createTemp();
    },
    // ダイアログを閉じる
    closeDialog() {
      this.search.show = false;
    },
    // 何もせず閉じる
    close() {
      // 検索せず閉じる場合、開いた時の検索条件に戻す
      this.rollbackTemp();
      this.closeDialog();
    },
    // フォームをクリアする
    resetDialog() {
      this.$formulate.reset('search_form', {});
      this.search.formSelected = [];
      this.search.baseDatetime.context.model = null;
    },

    /**
     * temp
     */
    createTemp() {
      this.search.conditionTemp = structuredClone(this.search.condition);
      this.search.formSelectedTemp = structuredClone(this.search.formSelected);
      this.search.baseDatetime.modelTemp = structuredClone(
        this.search.baseDatetime.context.model,
      );
    },
    rollbackTemp() {
      this.search.condition = this.search.conditionTemp;
      this.search.formSelected = this.search.formSelectedTemp;
      this.search.baseDatetime.context.model = this.search.baseDatetime.modelTemp;
    },

    ...mapActions('snackbar', ['openSnackBar', 'saveFail']),
  },
};
</script>

<style lang="scss">
.v-application div.v-card.listSearchCard div.v-card__text {
  padding-top: 0 !important;
}
</style>
