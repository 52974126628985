var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',_vm._b({directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],ref:"listViewDataTable",class:{
    'elevation-0': true,
    cdsTable: true,
    listView: true,
    dataTableEditMode: _vm.list.editMode,
    dataTableNotEditMode: !_vm.list.editMode,
    clickable: _vm.isRowClickable,
  },attrs:{"headers":_vm.list.displayHeaders,"items":_vm.list.listData,"item-key":_vm.list.uniqueKeyName,"item-class":(item) => {
      let classes = [];
      const errataType = item.ErrataType__c;
      if (errataType === '訂正') classes = [...classes, 'errata-revise'];
      if (errataType === '取消') classes = [...classes, 'errata-cancel'];
      return classes.join(' ');
    },"show-select":_vm.computedShowCheckbox,"options":_vm.list.options,"selectable-key":_vm.list.selectableKeyName,"hide-default-footer":_vm.list.editMode,"disable-sort":_vm.list.editMode,"mobile-breakpoint":0},on:{"update:options":[function($event){return _vm.$set(_vm.list, "options", $event)},_vm.handleUpdateOptions],"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{ref:"listButtons"},[(_vm.applyMaxFields)?_c('TableMaxInformation'):_vm._e(),_c('ListButtons',_vm._b({on:{"toggle-list-edit-mode":_vm.handleToggleEditMode,"list-save":_vm.handleListSave,"reload-data":_vm.handleReloadData,"update:display-headers":_vm.handleUpdateDisplayHeaders}},'ListButtons',_vm.listButtonProps,false))],1)]},proxy:true},{key:"item.data-table-select",fn:function({ isSelected, select, item }){return [(
        !_vm._.has(item, _vm.list.selectableKeyName) || item[_vm.list.selectableKeyName]
      )?_c('v-simple-checkbox',{attrs:{"color":"green","value":isSelected,"ripple":false},on:{"input":function($event){return select($event)}}}):_vm._e()]}},_vm._l((_vm.list.displayHeaders),function(header){return {key:`item.${header.value}`,fn:function(props){return [_c('Display',{key:header.value,attrs:{"item-props":props,"field-info":props.header.fieldInfo,"is-max-value":_vm.applyMaxFields && props.item.__maxFields.includes(header.value)},model:{value:(props.value),callback:function ($$v) {_vm.$set(props, "value", $$v)},expression:"props.value"}})]}}}),(_vm.viewAttachment)?{key:"item.attachmentNum",fn:function(props){return [(props.item.attachmentNum)?_c('div',{staticClass:"mr-2 ml-n2"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-paperclip ")]),_c('v-badge',{attrs:{"color":"success","content":props.item.attachmentNum,"offset-x":"0","offset-y":"6"}})],1):_vm._e()]}}:null,(_vm.viewComment)?{key:"item.commentNum",fn:function(props){return [(props.item.commentNum)?_c('div',{staticClass:"mr-2 ml-n2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"dense":""}},[_vm._v(" mdi-comment-text-multiple ")]),_c('v-badge',{attrs:{"color":"success","content":props.item.commentNum,"offset-x":"0","offset-y":"6"}})],1):_vm._e()]}}:null,_vm._l((_vm.itemSlotComponents),function(com,comIndex){return {key:com.slotName,fn:function(props){return [_c(com.componentName,_vm._b({key:comIndex,tag:"component"},'component',{
        ...props,
        ..._vm.forInjectComponentData,
        ...com.attr,
      },false))]}}}),_vm._l((_vm.slots.datatable),function(slotName){return {key:slotName,fn:function(props){return [_vm._t(`datatable.${slotName}`,null,null,props)]}}})],null,true),model:{value:(_vm.list.selected),callback:function ($$v) {_vm.$set(_vm.list, "selected", $$v)},expression:"list.selected"}},'v-data-table',_vm.list.dataTableProps,false))
}
var staticRenderFns = []

export { render, staticRenderFns }