<template>
  <v-btn
    v-if="list.editMode && !!bulkFieldSetName && !$vuetify.breakpoint.xs"
    v-show="!hidden"
    depressed
    outlined
    color="primary"
    class="mr-3"
    :disabled="disabled"
    @click="dialog.show = true"
  >
    <v-icon left>
      mdi-playlist-edit
    </v-icon>
    <span>一括入力</span>
    <v-dialog
      v-model="dialog.show"
      persistent
      scrollable
      max-width="600px"
      eager
    >
      <v-card v-if="!!fieldSet">
        <v-card-title>
          <span class="text-h5">一括入力</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <FieldSetInput
              v-model="bulkObject"
              :field-set="fieldSet"
              :field-info-list="objectInfo.properties"
              :do-reset="resetEvent"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="bulkCancel">
            閉じる
          </v-btn>
          <v-btn color="blue darken-1" dark depressed @click="setBulkFields">
            設定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import FieldSetInput from '../FieldSetInput';
import { mapActions } from 'vuex';

export default {
  name: 'Bulk',
  components: {
    FieldSetInput,
  },

  props: {
    /********** 共通 **********/
    // オブジェクト名
    objectName: { type: String, required: true },
    // オブジェクト情報
    objectInfo: { type: Object, required: true },

    /********** bulk **********/
    list: { type: Object, default: () => ({}) },
    // 一括入力項目セット名
    bulkFieldSetName: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    dialog: {
      show: false,
    },
    fieldSet: null,
    // 一括入力するデータ
    bulkObject: {},
    // この値を検知して項目セットの入力をクリアする
    resetEvent: null,
  }),

  computed: {
    disabled() {
      return (
        this.list.selected.length === 0 ||
        !this.bulkFieldSetName ||
        !this.fieldSet
      );
    },
    hidden() {
      return !this.fieldSet;
    },
  },

  watch: {
    bulkFieldSetName(to, from) {
      if (to !== from) {
        this.loadFieldSet();
      }
    },
  },

  mounted() {
    this.loadFieldSet();
  },

  methods: {
    // 一括入力
    async setBulkFields() {
      // 選択しているitemに設定
      this.list.selected.map((d) => {
        Object.entries(this.bulkObject).map(([fieldPath, newValue]) => {
          this.$set(d, fieldPath, newValue);
        });
      });
      // eslint-disable-next-line vue/no-mutating-props
      this.list.selected = [];
      await this.bulkCancel();
    },

    // 一括キャンセル
    async bulkCancel() {
      this.resetEvent = new Date();
      this.dialog.show = false;
    },

    // 項目セットの読み込み
    async loadFieldSet() {
      const { fieldSet } = await this.$store.dispatch(
        'loading/register',
        this.$util.getFieldSet(this.objectName, this.bulkFieldSetName),
      );
      this.$set(this, 'fieldSet', fieldSet || null);
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
