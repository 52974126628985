import Vue from 'vue';
import { uniqueId, maxBy, keyBy, omitBy, isEqual } from 'lodash';

/**
 * スロット名の配列からprefixを削除して返す
 * @param {String} prefix スロット名のprefix
 * @param {Array} slots スロット
 * @param {Array} scopedSlots スコープ付きスロット
 */
export function getSlots(prefix, slots, scopedSlots) {
  return [...Object.keys(slots), ...Object.keys(scopedSlots)]
    .filter((value, index, origin) => {
      return origin.indexOf(value) === index && value.startsWith(`${prefix}.`);
    })
    .map((v) => v.replace(new RegExp(`^${prefix}.`), ''));
}

/**
 * 項目セットを取得する
 * @param {string} objectName オブジェクト名
 * @param {string} fieldSetName 項目セット名
 * @returns 項目セット
 */
export async function getFieldSet(objectName, fieldSetName) {
  const result = await Vue.prototype.$store.dispatch(
    'loading/register',
    Vue.prototype.$util.getFieldSet(objectName, fieldSetName),
  );
  return result;
}

/**
 * 一覧のデータ取得
 * @param {Object} option オプション
 * @param {String} option.controller コントローラー
 * @param {String} option.method メソッド
 * @param {Boolean} option.hasVersion バージョン情報を持っているか
 * @param {Boolean} option.le listedit
 * @param {Object} option.list 一覧
 * @param {Object} option.customListProperty 追加のプロパティ
 * @param {String} option.objectName オブジェクト名
 * @param {String} option.objectInfo オブジェクト情報
 * @param {Object} option.searchObject 検索条件
 * @param {String} option.disasterId 災害Id
 * @param {String} option.masterObjectName マスタオブジェクト名
 * @param {String} option.masterObjectInfo マスタオブジェクト情報
 * @param {Array} option.noCopyField マスタから動的オブジェクトにコピーしない項目
 * @param {String} option.masterFieldName 動的オブジェクトのマスタIdの項目
 * @param {Object} option.paramsExtension paramsに追加するオブジェクト
 */
export async function loadRecords({
  controller,
  method,
  hasVersion,
  le,
  list,
  customListProperty,
  objectName,
  searchObject,
  disasterId,
  masterObjectName,
  masterObjectInfo,
  noCopyField,
  masterFieldName,
  paramsExtension,
}) {
  // リクエストの組み立て
  const invokeRequest = {
    controller,
    method,
    params: await createInvokeRequestParams({
      hasVersion,
      le,
      list,
      customListProperty,
      objectName,
      searchObject,
      disasterId,
      masterObjectName,
      masterObjectInfo,
      noCopyField,
      masterFieldName,
      paramsExtension,
    }),
  };

  console.debug(
    '%csearch invoke final request ->',
    'color: aqua;',
    invokeRequest,
  );

  const res = await Vue.prototype.$con.invoke(invokeRequest);
  return {
    res,
    invokeRequest,
  };
}

/**
 * 一覧のデータ取得
 * @param {Object} option オプション
 * @param {Boolean} option.hasVersion バージョン情報を持っているか
 * @param {Boolean} option.le listedit
 * @param {Object} option.list 一覧
 * @param {Object} option.customListProperty 追加のプロパティ
 * @param {String} option.objectName オブジェクト名
 * @param {String} option.objectInfo オブジェクト情報
 * @param {Object} option.searchObject 検索条件
 * @param {String} option.disasterId 災害Id
 * @param {String} option.masterObjectName マスタオブジェクト名
 * @param {String} option.masterObjectInfo マスタオブジェクト情報
 * @param {Array} option.noCopyField マスタから動的オブジェクトにコピーしない項目
 * @param {String} option.masterFieldName 動的オブジェクトのマスタIdの項目
 * @param {Object} option.paramsExtension paramsに追加するオブジェクト
 * @returns
 */
export async function createInvokeRequestParams({
  hasVersion,
  le,
  list,
  customListProperty,
  objectName,
  searchObject,
  disasterId,
  masterObjectName,
  masterObjectInfo,
  noCopyField,
  masterFieldName,
  paramsExtension,
}) {
  const params = {
    objectName,
    condition: searchObject,
    listOptions: structuredClone(list.options),
    listProperty: {
      mode: hasVersion ? (le === true ? 'input' : 'fixed') : null,
      ...customListProperty,
    },
    disasterId,
    masterObjectName,
    masterCondition: masterObjectName
      ? await createMasterCondition({
          searchObject: structuredClone(searchObject),
          masterObjectInfo,
          masterFieldName,
        })
      : undefined,
    masterListOptions: masterObjectName
      ? await createMasterListOptions({
          options: structuredClone(list.options),
          masterObjectInfo,
        })
      : undefined,
    noCopyField,
    masterFieldName,
    ...paramsExtension,
  };

  return params;
}

/**
 * マスター用の検索条件に変更(マスターにはない項目を除去)
 * @param {Object} param0.searchObject 検索オブジェクト
 * @param {Object} param0.masterObjectInfo マスターオブジェクト情報
 * @param {Object} param0.masterFieldName マスターオブジェクト名
 * @returns
 */
async function createMasterCondition({
  searchObject,
  masterObjectInfo,
  masterFieldName,
}) {
  // マスタのfieldPath
  const masterFieldPaths = Object.keys(masterObjectInfo.properties);

  // 許可するキー
  const validKeys = [...prismaFilterKeys, ...masterFieldPaths, masterFieldName]
    .map((v) => v.toLowerCase())
    // Idは違うので除外
    .filter((key) => !['id'].includes(key.toLowerCase()));

  // 不要なキーは除外する
  // マスターのオブジェクト名のキーがあればIdに置き換える
  const result = exceptInvalidKey(searchObject, {
    validKeys,
    replaceKeys: {
      [masterFieldName]: 'Id',
    },
  });

  return result;
}

/**
 * prismaのクエリのキー
 * @see https://www.prisma.io/docs/reference/api-reference/prisma-client-reference#filter-conditions-and-operators
 */
const prismaFilterKeys = [
  'equals',
  'not',
  'in',
  'notin',
  'lt',
  'lte',
  'gt',
  'gte',
  'contains',
  'search',
  'mode',
  'startswith',
  'endswith',
  'and',
  'or',
];

/**
 * 不要なキーを除外する
 * @param {*} obj オブジェクト等
 * @param {Array} params1.validKeys 認められたキー
 * @param {Array} params1.replaceKeys 置き換えるキー
 * @returns
 */
function exceptInvalidKey(obj, props) {
  const { validKeys, replaceKeys = {} } = props;
  // 値がない場合はそのまま返す
  if (!obj) {
    return obj;
  }
  // 配列のとき
  if (Array.isArray(obj)) {
    // 各値をフィルターにかける
    const filteredArray = obj
      .map((o) => exceptInvalidKey(o, props))
      .filter((v) => v !== undefined);
    // 残った値があればそのまま返し、なければないことを返す
    const result = filteredArray.length > 0 ? filteredArray : undefined;
    return result;
  }
  if (typeof obj === 'object') {
    // 各キーをフィルターにかける&各value
    const filteredObj = Object.entries(obj)
      // まずは各キーをフィルターする
      .filter(([key]) => validKeys.includes(key.toLowerCase()))
      .reduce((prev, [key, value]) => {
        const newValue = exceptInvalidKey(value, props);
        // 計算後undefinedならそのまま続ける
        if (newValue === undefined) return prev;
        // ちゃんと値があればそれを付与する
        else {
          return {
            ...prev,
            // キーを置き換える場合はここで実施
            [replaceKeys[key] || key]: newValue,
          };
        }
      }, {});
    // 残っていればそれを返し、なければないことを返す
    const result =
      Object.keys(filteredObj).length > 0 ? filteredObj : undefined;
    return result;
  }
  return obj;
}

/**
 * マスター用のリストオプションに変更(マスターにはない項目を除去)
 * @param {Object} param0.options リストオプション
 * @param {Object} param0.masterObjectInfo マスターオブジェクト情報
 * @returns
 */
async function createMasterListOptions({ options, masterObjectInfo }) {
  // マスタのfieldPath
  const masterFieldPaths = Object.keys(masterObjectInfo.properties);

  // sortByに不要な項目がないか確認して問題ないものを取り出し
  const { sortBy, sortDesc } =
    options.sortBy
      // 項目とdescがバラバラなのでまとめる
      ?.map((sortField, i) => ({
        sortField,
        sortDesc: options.sortDesc?.[i],
      }))
      // マスタにある項目だけに絞る
      .filter(({ sortField }) => masterFieldPaths.includes(sortField))
      // 出力
      .reduce(
        ({ sortBy, sortDesc }, { sortField: f, sortDesc: d }) => {
          return {
            sortBy: [...sortBy, f],
            sortDesc: [...sortDesc, d],
          };
        },
        { sortBy: [], sortDesc: [] },
      ) || {};

  const result = {
    ...options,
    sortBy,
    sortDesc,
  };

  return result;
}

/**
 * 各行にキーを追加する
 */
export async function applyFieldsToListData({
  records,
  uniqueKeyName,
  selectableKeyName,
  viewAttachment,
  viewComment,
  objectName,
  list,
  le,
  applyMaxFields,
  // 最大値を設定する時のみ必要
  objectInfo = {},
}) {
  // 添付ファイルの個数を取得する
  const attachmentObject = viewAttachment
    ? await getAttachmentValue({
        records,
        viewAttachment,
        objectName,
      })
    : {};

  // 各項目の最大値を取得する
  const fieldMaxValues = applyMaxFields
    ? await getFieldMaxValues({ records, objectInfo })
    : {};
  let result = records.map((r) => {
    return {
      ...r,
      // 各行にユニークキーを発行する
      [uniqueKeyName]: r[uniqueKeyName] || uniqueId(uniqueKeyName),
      // 添付ファイル
      ...(viewAttachment
        ? {
            attachmentNum:
              r.attachmentNum ||
              attachmentObject[r.Id]?.attachments?.length ||
              0,
          }
        : {}),
      // コメント数
      ...(viewComment ? { commentNum: r.comments?.length || 0 } : {}),
      // 最大値
      ...(applyMaxFields
        ? {
            __maxFields: Object.entries(fieldMaxValues)
              .filter(
                ([fieldPath, maxValue]) => Number(r[fieldPath]) === maxValue,
              )
              .map(([fieldPath]) => fieldPath),
          }
        : {}),
    };
  });

  // 選択可能な項目かを判定する
  result = applyListDataRow({
    records: result,
    editMode: list.editMode,
    le,
    selectableKeyName,
    uniqueKeyName,
  });

  return result;
}

/**
 * 最大値を取得する
 * @param {*} param0.records レコード
 * @param {*} param0.objectInfo オブジェクト情報
 * @returns 型: {[fieldPath]: number}
 */
async function getFieldMaxValues({ records, objectInfo }) {
  const maxValues = Object.entries(objectInfo.properties)
    .map(([fieldPath, fieldInfo]) => ({ fieldPath, ...fieldInfo }))
    // リレーション項目でない数値項目に制限
    .filter((v) => !v.relationField && ['number', 'integer'].includes(v.type))
    // バージョンの最大値は対象外
    .filter((v) => v.fieldPath !== 'Version__c')
    // 最大値を取得する
    .map((v) => {
      return {
        ...v,
        maxValue: maxBy(records, (x) => Number(x[v.fieldPath] || 0))?.[
          v.fieldPath
        ],
      };
    })
    // 最大値があるものだけ数値として出力
    .filter((v) => v.maxValue !== null)
    .reduce((prev, next) => {
      return {
        ...prev,
        [next.fieldPath]: Number(next.maxValue),
      };
    }, {});
  return maxValues;
}

/**
 * 行の選択が可能かをレコードに反映して返す
 * @param {*} records
 * @param {*} editMode
 * @param {*} le
 * @param {*} selectableKeyName
 * @param {*} uniqueKeyName
 * @returns
 */
export function applyListDataRow({ records, editMode, le, selectableKeyName }) {
  return records.map((r) => {
    return {
      ...r,
      // 選択可能か
      [selectableKeyName]: isSelectableItem(r, editMode, le),
    };
  });
}

/**
 * 行の選択が可能か判定して返す
 * @param {*} item
 * @param {*} list
 * @param {*} le
 * @returns
 */
export function isSelectableItem(item, editMode, le) {
  if (editMode) {
    // 編集モード時
    // 一括入力であると思われるので、基本的に全項目の選択を許可する
    return true;
  } else {
    // 閲覧モード時
    // バージョン情報がある場合は、確定させる項目にチェックを付けると思われるので、Idがある かつ fixしていないレコードを許可する
    if (le === true) {
      return !!(item.Id && !item.IsFixed__c);
    }
    // バージョン情報がない場合は、特に制限させる必要はないかと思われる
    else {
      return true;
    }
  }
}

/**
 * 添付ファイルの情報を取得する
 */
export async function getAttachmentValue({
  records,
  viewAttachment,
  objectName,
}) {
  if (viewAttachment && records) {
    //データのIDリスト
    const listDataIds = records.map((data) => data.Id).filter((v) => !!v);
    if (listDataIds.length === 0) {
      return {};
    }
    //データの添付ファイルリスト
    const allAttachmentList = await loadAttachment({
      ids: listDataIds,
      objectName,
    });
    // GISのファイルを削除
    const attachmentList = allAttachmentList
      .map((val) => (val.attachments ? val : { ...val, attachments: [] }))
      .filter(
        (x) =>
          (x.attachments = x.attachments.filter(
            (v) => !v.Name.match(/gis_(.*)_data.json/),
          )),
      );
    //データIDをキーでオブジェクトを生成
    const attachmentObjects = keyBy(attachmentList, 'object.Id');
    return attachmentObjects;
  }
  return {};
}

/**
 * 添付ファイルのデータを取得する
 * @param {Array} ids
 * @param {String} objectName
 */
export async function loadAttachment({ ids, objectName }) {
  const res = await Vue.prototype.$con.invoke({
    controller: 'CDS_CTR_Common',
    method: 'getAttachment',
    params: {
      objectName,
      ids: JSON.stringify(ids),
    },
  });
  return res;
}

/**
 * 一覧の一括保存
 * @param {Object} option オプション
 * @param {Array} option.listData 編集対象の一覧データ
 * @param {Array} option.listTempData 編集前の一覧データ
 * @param {String} option.objectName オブジェクト名
 * @param {String} option.controller コントローラー
 * @param {Object} option.fixedValue 固定値(IsFixed__c = false など)
 * @param {String} option.listSaveMethodName 一覧保存RemoteActionメソッド名
 * @param {String} option.disasterId 災害Id(災害に紐づける場合に指定する)
 * @param {String} option.validate バリデート
 * @param {String} option.store store
 */
export async function saveListData(option) {
  const {
    listData = [],
    listTempData = [],
    objectName,
    controller,
    fixedValue = {},
    listSaveMethodName = 'listSave',
    disasterId = null,
    validate = null,
    store = {},
  } = option;

  // 保存対象レコード(差分の項目を含む)
  let saveTargetRecords = [];
  // 保存対象レコード(すべての項目を含む)
  let saveTargetFullRecords = [];
  // 変更があったレコードだけ取り出し
  for (let i = 0; i < listData.length; i++) {
    // 編集後データ
    const editTarget = listData[i];
    // 編集前データ
    const originData = listTempData[i];

    // 変更されたものを取り出し
    const diff = omitBy(editTarget, (v, k) => isEqual(originData[k], v));
    // console.log('%cdiff ->', 'color: red;', diff);
    if (
      diff &&
      Object.keys(diff).length !== 0 &&
      // attributesだけがある場合は除く
      !(Object.keys(diff).length === 1 && diff.attributes)
    ) {
      // 保存対象に追加
      saveTargetFullRecords.push(listData[i]);
      // 既存データの編集の場合
      if (originData.Id) {
        // 変更があったものは、Idとattributesを付与して保存対象に追加
        saveTargetRecords.push({
          Id: originData.Id,
          ...diff,
          attributes: { type: objectName },
          ...fixedValue,
        });
      }
      // マスタデータ等で新規追加の場合
      else {
        // 災害Idを付与するためのオブジェクト
        const disasterObject = disasterId
          ? { CDS_T_Disaster__c: disasterId }
          : null;
        // 新規なので全項目保存対象
        saveTargetRecords.push({
          ...editTarget,
          ...disasterObject,
          attributes: { type: objectName },
          ...fixedValue,
        });
      }
    }
  }
  // console.log('%csaveTargetRecords ->', 'color: red;', saveTargetRecords);

  // バリデートがある場合は実施
  if (validate) {
    const validateResult = await validate(saveTargetFullRecords, option);
    if (validateResult !== true) {
      throw new Error(validateResult);
    }
  }

  // 保存対象のデータがあれば、保存を実施
  if (saveTargetRecords.length !== 0) {
    // ユーザ情報を取り出し
    const {
      user: { user },
    } = store.state;
    // 入力者情報
    const reporterInfo = {
      // 一覧編集で暗黙的に入力者氏名を設定する場合は必須項目である氏名を設定する
      Reporter__c: user.Name,
      ReporterAffiliation__c: user.Department__c,
      ReporterTel__c: user.Phone,
    };
    // 入力者情報がなければ代入
    saveTargetRecords.forEach((s) => {
      Object.keys(reporterInfo).forEach((rf) => {
        if (!s[rf]) {
          s[rf] = reporterInfo[rf];
        }
      });
    });

    await Vue.prototype.$con.invoke({
      controller: controller,
      method: listSaveMethodName,
      params: {
        records: JSON.stringify(saveTargetRecords),
      },
    });
  }
}

/**
 * 確定操作を行う
 * @param {Object} option オプション
 * @param {Array} option.records 確定対象レコード
 * @param {String} option.objectName オブジェクト名
 * @param {String} option.controller コントローラー名
 * @param {String} option.method メソッド名(default: fixRecordList)
 */
export async function fixRecords(option) {
  const { records, objectName, controller, method = 'fixRecordList' } = option;
  const fixTargetRecords = records
    // IDがあるものだけを取り出し
    .filter((record) => record.Id)
    // Idとattributesのみのオブジェクトを作成
    .map((record) => ({
      Id: record.Id,
      attributes: { type: objectName },
    }));
  // console.log('%cfix target ->', 'color: pink;', fixTargetRecords);
  if (fixTargetRecords.length !== 0) {
    await Vue.prototype.$con.invoke({
      controller,
      method,
      params: {
        records: JSON.stringify(fixTargetRecords),
      },
    });
    // console.log('%cfixed result ->', 'color: pink;', res);
  }
}

/**
 * 一括削除を行う
 * @param {Object} option オプション
 * @param {Array} option.records 削除対象レコード
 * @param {String} option.objectName オブジェクト名
 * @param {String} option.controller コントローラー名
 * @param {String} option.method メソッド名(default: deleteRecords)
 */
export async function deleteRecords(option) {
  const { records, objectName, controller, method = 'deleteRecords' } = option;
  const deleteTargetRecords = records
    // IDがあるものだけを取り出し
    .filter((record) => record.Id)
    // Idのみのオブジェクトを作成
    .map((record) => ({
      Id: record.Id,
    }));
  // console.log('%delete target ->', 'color: pink;', fixTargetRecords);
  if (deleteTargetRecords.length !== 0) {
    await Vue.prototype.$con.invoke({
      controller,
      method,
      params: {
        objectName,
        records: deleteTargetRecords,
      },
    });
    // console.log('%deleted result ->', 'color: pink;', res);
  }
}

/**
 * 項目セットから表示するヘッダーを返す
 */
export async function getDisplayHeader({
  objectName,
  objectInfo,
  listFieldSetName,
  inputFieldSetName,
  le,
  viewAttachment,
  viewComment,
}) {
  // オブジェクトの情報を取得

  // 項目セットの名称から項目セットを取得してヘッダーの形にして返す
  let [listFieldSet, inputFieldSet] = await Promise.all(
    [listFieldSetName, inputFieldSetName].map(async (fieldSetName) => {
      // 項目セットを取得する
      const { fieldSet } = await Vue.prototype.$util.getFieldSet(
        objectName,
        fieldSetName,
      );
      // ヘッダーの形式にして返す
      return (
        fieldSet &&
        fieldSetToHeader(fieldSet, objectInfo, viewAttachment, viewComment)
      );
    }),
  );

  // すべての項目
  let all = [];

  // 入力モードの場合はinputを返す
  if (le) {
    // inputがなければ通常のを返す
    all = inputFieldSet || listFieldSet;
  }
  // 通常の場合は普通の項目セットからのヘッダーを返す
  else {
    all = listFieldSet;
  }

  return all;
}

/**
 * 項目セットからリストヘッダーを生成する
 */
export function fieldSetToHeader(
  fieldSet,
  objectInfo,
  viewAttachment,
  viewComment,
) {
  // 項目セットがなければそのまま変えsう
  if (!fieldSet) return fieldSet;
  if (!objectInfo?.properties) return fieldSet;
  const fieldInfoList = objectInfo?.properties;
  const header = fieldSet?.fields?.map((f) => {
    const fieldInfo = fieldInfoList[f.fieldPath];
    // ヘッダーの形にして返す
    return {
      text: fieldInfo?.label,
      value: f.fieldPath,
      fieldInfo,
      sortable: fieldInfo !== undefined && !fieldInfo.relationField,
    };
  });
  // 添付ファイルがある場合は追加
  const result = [
    ...((viewAttachment && [addAttachmentFields]) || []),
    ...((viewComment && [addCommentFields]) || []),

    ...header,
  ];
  return result;
}

/**
 * 添付ファイルを表示するヘッダー
 */
const addAttachmentFields = {
  text: '添付',
  value: 'attachmentNum',
  fieldInfo: {
    type: 'boolean',
    label: '添付ファイル',
    enum: [],
    picklistValues: [],
    sortable: false,
  },
  sortable: false,
};
/**
 * コメント数を表示するヘッダー
 */
const addCommentFields = {
  text: 'コメント',
  value: 'commentNum',
  fieldInfo: {
    type: 'boolean',
    label: 'コメント数',
    enum: [],
    picklistValues: [],
    sortable: false,
  },
  sortable: false,
};

/**
 * 時点情報の作成
 * @param {String} option.controller コントローラー
 * @param {String} option.method メソッド
 * @param {String} option.objectName オブジェクト名
 * @param {Object} option.searchObject 検索条件
 * @param {String} option.disasterId 災害Id
 * @param {Object} option.customListProperty カスタムリストプロパティ
 * @param {Object} option.paramsExtension paramsに追加するオブジェクト
 * @returns
 */
export async function invokeCreateTotalRequest({
  controller,
  method,
  objectName,
  searchObject,
  disasterId,
  customListProperty,
  paramsExtension,
}) {
  const invokeRequest = {
    controller,
    method,
    params: {
      objectName,
      condition: searchObject,
      disasterId,
      listOptions: {},
      listProperty: {
        mode: 'fixed',
        ...customListProperty,
      },
      ...paramsExtension,
    },
  };
  const res = await Vue.prototype.$con.invoke(invokeRequest);
  return {
    res,
    invokeRequest,
  };
}
