var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-section',_vm._b({scopedSlots:_vm._u([{key:"bottom_title",fn:function(){return [_vm._t("bottom_title")]},proxy:true},{key:"bottom_row",fn:function(){return [_vm._t("bottom_row")]},proxy:true}],null,true)},'form-section',{
    title: _vm.section.label,
    hidden: _vm.hidden,
    notShow: _vm.notShow,
    editMode: _vm.editMode,
  },false),[_c('v-col',[_vm._l((_vm.section.items),function(item){return [_c('div',{key:item.fieldPath,staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-0 flex-shrink-0"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","value":item.fieldPath},model:{value:(_vm.editValue),callback:function ($$v) {_vm.editValue=$$v},expression:"editValue"}})],1),_c('div',{staticClass:"flex-grow-1 flex-shrink-1"},[(Object.keys(_vm.itemSlotComponents).includes(item.fieldPath))?_c(_vm.itemSlotComponents[item.fieldPath].componentName,_vm._b({key:item.fieldPath,tag:"component",on:{"input":function($event){return _vm.handleValueChanged($event, item)}}},'component',{
              section: _vm.section,
              item,
              editMode: _vm.editMode,
              hidden: _vm.hidden,
              readonly: _vm.readonly,
              customValidations: _vm.customValidations,
              controller: _vm.controller,
            },false)):_c('field',{attrs:{"hidden":_vm.hiddenFieldNames.indexOf(item.fieldPath) > -1,"edit-mode":_vm.editMode,"readonly":_vm.readonly || _vm.readonlyFieldNames.includes(item.fieldPath),"field-info":_vm.fieldInfoList[item.fieldPath],"field-path":item.fieldPath,"column":_vm.section.column,"custom-validation":_vm.customValidations.find((v) => v.fieldName === item.fieldPath) ||
              null,"input-props":_vm.fieldInputProps[item.fieldPath]},on:{"input":function($event){return _vm.handleValueChanged($event, item)}}})],1)])]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }