<template>
  <div class="d-flex">
    <div class="pt-4 pb-2 d-flex">
      <Create v-bind="createProps" @create="handleCreate" />
      <Edit
        v-bind="editProps"
        @toggle-list-edit-mode="$emit('toggle-list-edit-mode')"
      />
      <Fix v-bind="fixProps" @reload-data="handleReloadData" />
      <Delete v-bind="deleteProps" @reload-data="handleReloadData" />
      <Bulk v-bind="bulkProps" />
      <CancelEdit
        v-bind="cancelEditProps"
        @cancel-list-edit="handleCancelEdit"
      />
      <Save v-bind="saveProps" @list-save="handleSave" />
      <!-- left-end -->
      <template
        v-for="com of itemSlotComponents.filter(
          (c) => c.location === 'leftEnd',
        )"
      >
        <component
          :is="com.componentName"
          v-bind="com.props"
          :key="com.componentName"
        ></component>
      </template>
    </div>
    <v-spacer></v-spacer>
    <div class="pt-2 pb-1 d-flex align-end">
      <!-- right-start -->
      <div class="text-caption mr-2">
        <span class="d-none d-sm-inline">背景色:</span>
        <v-chip small class="mx-1 rounded-0 errata-cancel">
          取消
        </v-chip>
        <v-chip small class="mx-1 rounded-0 errata-revise">
          訂正
        </v-chip>
      </div>
      <template
        v-for="com of itemSlotComponents.filter(
          (c) => c.location === 'rightStart',
        )"
      >
        <component
          :is="com.componentName"
          v-bind="com.props"
          :key="com.componentName"
        ></component>
      </template>
      <template v-if="!list.editMode">
        <Reload v-bind="reloadProps" @reload-data="handleReloadData" />
        <Csv v-if="canCsvDownload" v-bind="csvProps" />
        <SelectDisplayFields
          v-bind="selectDisplayFieldsProps"
          @update:display-headers="$emit('update:display-headers', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Create from './Create.vue';
import Edit from './Edit.vue';
import Fix from './Fix.vue';
import Delete from './Delete.vue';
import Bulk from './Bulk.vue';
import CancelEdit from './CancelEdit.vue';
import Save from './Save.vue';
import Reload from './Reload.vue';
import Csv from './Csv.vue';
import SelectDisplayFields from './SelectDisplayFields.vue';

export default {
  components: {
    Create,
    Edit,
    Fix,
    Delete,
    Bulk,
    CancelEdit,
    Save,
    Reload,
    Csv,
    SelectDisplayFields,
  },

  inject: {
    listButton: {
      default: () => ({}),
    },
  },

  props: {
    /********** 共通 **********/
    // オブジェクト名
    objectName: { type: String, required: true },
    // オブジェクト情報
    objectInfo: { type: Object, required: true },
    list: { type: Object, default: () => ({}) },
    listName: { type: String, default: null },
    le: { type: Boolean, default: undefined },
    // バージョン情報を持っているか
    hasVersion: { type: Boolean, default: false },
    // 詳細ページ名
    detailPageName: { type: String, default: null },

    /********** create **********/
    canCreate: { type: Boolean, default: true },
    createButtonName: { type: String, default: '新規作成' },
    // 新規作成の遷移
    createPage: { type: Function, default: null },

    /********** edit **********/
    canEdit: { type: Boolean, default: true },

    /********** fix **********/
    canFix: { type: Boolean, default: true },

    /********** delete **********/
    canDelete: { type: Boolean, default: false },

    /********** bulk **********/
    // 一括入力項目セット名
    bulkFieldSetName: {
      type: String,
      default: null,
    },

    /********** reload **********/
    canReload: { type: Boolean, default: true },

    /********** csv **********/
    // CSVファイル名フォーマット
    csvFileNameFormat: { type: String, default: null },
    // CSV項目セット名
    csvFieldSetName: { type: String, default: null },
    // ダウンロード可
    canCsvDownload: { type: Boolean, default: true },
    // CSV出力設定
    csvOutputConfig: {
      type: Object,
      default: () => ({}),
      /* sample
      {
        ThisIsCustomField__c: (value, fieldInfo, record) => {
          return value;
        }
      }
       */
    },

    /********** select **********/
    // 一覧項目セット名
    listFieldSetName: { type: String, default: 'ListFieldSet' },
    // 一覧項目セット名(初期表示)
    defaultListFieldSetName: { type: String, default: 'DefaultListFieldSet' },
    // 一覧項目セット名(inputモード)
    inputFieldSetName: { type: String, default: 'ListInputFieldSet' },
    // 一覧項目セット名(inputモード)(初期表示)
    defaultInputFieldSetName: {
      type: String,
      default: 'DefaultListInputFieldSet',
    },
    canSelectFields: { type: Boolean, default: true },
    // 添付ファイルを表示させるか
    viewAttachment: { type: Boolean, default: false },
    // コメント数を表示させるか
    viewComment: { type: Boolean, default: false },
  },

  data: () => ({
    itemSlotComponents: [],
  }),

  computed: {
    createProps() {
      const { list, le, canCreate, createButtonName } = this;
      return {
        list,
        le,
        canCreate,
        createButtonName,
      };
    },
    editProps() {
      const { list, le, canEdit } = this;
      return {
        list,
        le,
        canEdit,
      };
    },
    fixProps() {
      const { objectName, hasVersion, list, canFix } = this;
      return {
        objectName,
        hasVersion,
        list,
        canFix,
      };
    },
    deleteProps() {
      const { objectName, list, canDelete } = this;
      return {
        objectName,
        list,
        canDelete,
      };
    },
    bulkProps() {
      return {
        objectName: this.objectName,
        objectInfo: this.objectInfo,
        list: this.list,
        bulkFieldSetName: this.bulkFieldSetName,
      };
    },
    cancelEditProps() {
      return {
        list: this.list,
      };
    },
    saveProps() {
      return {
        list: this.list,
      };
    },
    reloadProps() {
      const { list, canReload } = this;
      return {
        list,
        canReload,
      };
    },
    csvProps() {
      return {
        objectName: this.objectName,
        objectInfo: this.objectInfo,
        list: this.list,
        csvFileNameFormat: this.csvFileNameFormat,
        csvFieldSetName: this.csvFieldSetName,
        canCsvDownload: this.canCsvDownload,
        csvOutputConfig: this.csvOutputConfig,
        listFieldSetName: this.listFieldSetName,
      };
    },
    selectDisplayFieldsProps() {
      return {
        objectName: this.objectName,
        objectInfo: this.objectInfo,
        list: this.list,
        le: this.le,
        listFieldSetName: this.listFieldSetName,
        defaultListFieldSetName: this.defaultListFieldSetName,
        inputFieldSetName: this.inputFieldSetName,
        defaultInputFieldSetName: this.defaultInputFieldSetName,
        canSelectFields: this.canSelectFields,
        viewAttachment: this.viewAttachment,
        viewComment: this.viewComment,
      };
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.initializeInjectComponent();
    },

    // 作成ボタンハンドラー
    handleCreate() {
      if (this.createPage) {
        this.createPage();
      } else {
        this.transitionDetailPage({
          mode: 'n',
        });
      }
    },

    // 詳細ページに移動
    transitionDetailPage(options) {
      this.$transition.to(this.detailPageName, options);
    },

    // キャンセルハンドラー
    handleCancelEdit() {
      this.$emit('toggle-list-edit-mode');
    },

    // 保存ハンドラー
    handleSave() {
      this.$emit('list-save');
    },

    // リロードハンドラー
    handleReloadData() {
      this.$emit('reload-data');
    },

    /********** inject **********/
    // injectで指定されたコンポーネントを設定
    initializeInjectComponent() {
      // 一覧の名前
      const listName = this.listName || 'default';
      // injectで指定されているか確認
      // slotName, componentをキーに持つオブジェクトの配列
      const componentDefinitionRaw = this.listButton[listName];
      if (
        !componentDefinitionRaw ||
        Object.keys(componentDefinitionRaw).length === 0
      ) {
        return;
      }

      // コンポーネントの定義をリスト化
      const componentDefinition = Object.entries(componentDefinitionRaw).reduce(
        (prev, [nextKey, nextValue]) => {
          return [
            ...prev,
            ...nextValue.map((value) => {
              return {
                // コンポーネント名を作成
                componentName: _.uniqueId(`${listName}-listbuttoncomponent_`),
                location: nextKey,
                ...value,
                props: {
                  ...value.props,
                  instance: this,
                },
              };
            }),
          ];
        },
        [],
      );

      // コンポーネントの登録
      componentDefinition.map((com) => {
        // コンポーネントの登録
        this.$options.components[com.componentName] = com.component;
      });
      // 表示用のコンポーネントリストを作成しセット
      this.$set(this, 'itemSlotComponents', componentDefinition);
    },
  },
};
</script>
