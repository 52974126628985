<template>
  <v-tooltip top>
    <template #activator="{ on: onTooltip }">
      <v-menu
        v-if="!list.editMode && canSelectFields"
        offset-y
        allow-overflow
        :close-on-content-click="false"
      >
        <template #activator="{ on: onMenu, attrs }">
          <v-btn
            class="ml-2"
            icon
            small
            v-bind="attrs"
            v-on="{ ...onMenu, ...onTooltip }"
          >
            <v-icon>mdi-view-column</v-icon>
          </v-btn>
        </template>

        <v-list v-if="!list.editMode && canSelectFields">
          <div class="mt-2">
            <v-btn class="ml-2" depressed @click="handleSetDefault">
              初期表示に戻す
            </v-btn>
            <v-btn class="mx-2" depressed @click="handleSetAll">
              全て表示
            </v-btn>
          </div>
          <v-list-item
            v-for="(child, index) in displayHeaderAllFields"
            :key="index"
            class="checkBoxStyle"
            dense
          >
            <v-checkbox
              v-model="changeFields"
              :value="child.value"
              hide-details="auto"
              class="mt-0"
              dense
            >
              <template #label>
                <span class="text--primary text-body-2">{{ child.text }}</span>
              </template>
            </v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <span>表示項目選択</span>
  </v-tooltip>
</template>

<script>
import { getDisplayHeader } from '../util';

export default {
  props: {
    /********** 共通 **********/
    // オブジェクト名
    objectName: { type: String, required: true },
    // オブジェクト情報
    objectInfo: { type: Object, required: true },
    // listEdit
    le: { type: Boolean, default: undefined },

    /********** ヘッダー **********/
    // 一覧項目セット名
    listFieldSetName: { type: String, default: 'ListFieldSet' },
    // 一覧項目セット名(初期表示)
    defaultListFieldSetName: { type: String, default: 'DefaultListFieldSet' },
    // 一覧項目セット名(inputモード)
    inputFieldSetName: { type: String, default: 'ListInputFieldSet' },
    // 一覧項目セット名(inputモード)(初期表示)
    defaultInputFieldSetName: {
      type: String,
      default: 'DefaultListInputFieldSet',
    },

    /********** 共通 **********/
    list: { type: Object, default: () => ({}) },
    canSelectFields: { type: Boolean, default: true },
    // 添付ファイルを表示させるか
    viewAttachment: { type: Boolean, default: false },
    // コメント数を表示させるか
    viewComment: { type: Boolean, default: false },
  },

  data: () => ({
    // 選択項目
    changeFields: [],

    // すべての表示項目
    displayHeaderAllFields: [],
    // デフォルトの項目セット
    defaultHeaderFields: null,
  }),

  computed: {
    // 表示ヘッダー
    displayHeaders: {
      get() {
        return this.displayHeaderAllFields?.filter((f) =>
          this.changeFields.includes(f.value),
        );
      },
    },
    // すべての表示項目ヘッダー取得時のプロパティ
    loadFieldSetsWatchable() {
      // watchさせるために作成
      return [
        this.objectName,
        this.objectInfo,
        this.listFieldSetName,
        this.inputFieldSetName,
        this.le,
        this.viewAttachment,
        this.viewComment,
        this.defaultListFieldSetName,
        this.defaultInputFieldSetName,
      ];
    },
  },

  watch: {
    displayHeaders: {
      handler(to) {
        this.$emit('update:display-headers', to);
      },
      deep: true,
    },
    defaultHeaderFields: {
      handler() {
        this.handleSetInitial();
      },
      deep: true,
    },
    loadFieldSetsWatchable: {
      async handler(to, from) {
        if (JSON.stringify(to) !== JSON.stringify(from)) {
          await this.loadFieldSets();
        }
      },
      deep: true,
    },
  },

  async mounted() {
    await this.loadFieldSets();
  },

  methods: {
    async loadFieldSets() {
      await this.getDisplayHeaderAllFields();
      await this.getDefaultHeaderFields();
      this.handleSetInitial();
    },
    /**
     * すべての項目を取得する
     */
    async getDisplayHeaderAllFields() {
      const {
        objectName,
        objectInfo,
        listFieldSetName,
        inputFieldSetName,
        le,
        viewAttachment,
        viewComment,
      } = this;
      if (!objectInfo || Object.keys(objectInfo).length === 0) return;
      const result = await this.$store.dispatch(
        'loading/register',
        getDisplayHeader({
          objectName,
          objectInfo,
          listFieldSetName,
          inputFieldSetName,
          le,
          viewAttachment,
          viewComment,
        }),
      );
      this.displayHeaderAllFields = result;
    },
    /**
     * デフォルトの項目を取得する
     */
    async getDefaultHeaderFields() {
      const {
        objectName,
        defaultListFieldSetName,
        defaultInputFieldSetName,
        le,
      } = this;
      const { fieldSet } = await this.$store.dispatch(
        'loading/register',
        this.$util.getFieldSet(
          objectName,
          le ? defaultInputFieldSetName : defaultListFieldSetName,
        ),
      );
      this.$set(this, 'defaultHeaderFields', fieldSet);
    },

    // 初期値を設定
    handleSetInitial() {
      // デフォルトのヘッダーが読み込まれた後に動く
      this.handleSetDefault();
    },

    // 初期表示
    handleSetDefault() {
      if (this.defaultHeaderFields) {
        // 添付とコメントはApp.vueで表示していされていれば初期表示対象とする
        this.changeFields = [
          'attachmentNum',
          'commentNum',
          ...(this.defaultHeaderFields?.fields?.map((f) => f.fieldPath) || []),
        ];
      } else {
        // デフォルトの項目セットがない場合はすべて表示
        this.handleSetAll();
      }
    },

    // すべて表示
    handleSetAll() {
      this.changeFields = this.displayHeaderAllFields?.map((f) => f.value);
    },
  },
};
</script>
