import Vue from 'vue';
import iconv from 'iconv-lite';
import { stringify } from 'csv-stringify/sync';
import dateFnsPlugin from '@/plugins/datefns';

/**
 * CSVダウンロードを実施
 * @param {Object} option オプション
 * @param {String} option.csvString csv文字列
 * @param {Number} option.totalSize レコードの件数
 * @param {String} option.csvFileNameFormat 出力ファイル名フォーマット
 */
export const downloadCsv = async (option) => {
  const { csvString, totalSize, csvFileNameFormat } = option;

  // ファイル名置換を定義
  const fileNameReplace = {
    date: () => dateFnsPlugin.fnsFormat(new Date(), '', 'yyyy-MM-dd-HH-mm-ss'),
    count: () => totalSize,
  };

  // ファイル名
  let fileName = csvFileNameFormat || 'records-%date%-全%count%件.csv';

  // ファイル名の置換実施
  for (const [key, value] of Object.entries(fileNameReplace)) {
    fileName = fileName.replace(new RegExp(`%${key}%`, 'ig'), value());
  }

  // ダウンロード開始
  const blob = new Blob([iconv.encode(csvString, 'shift_jis')], {
    type: 'text/csv',
  });
  //IE,Edgeのダウンロード
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    //IE,Edge以外のダウンロード
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
};

/**
 * CSV用のデータ読み込み
 */
export const loadCsvRecords = async ({
  invokeRequest,
  progress,
  oneTimeRecords,
}) => {
  // 1ページあたりの行数をセット
  invokeRequest.params.listOptions.itemsPerPage = oneTimeRecords;

  // CSV出力対象レコード
  let resultRecords = [];

  // CSVレコードの最後の取得か
  let isLast = false;

  // トータルレコード件数
  let totalSize = 1;

  for (let page = 1; !isLast; page++) {
    // リクエストにページ番号をセット
    invokeRequest.params.listOptions.page = page;

    // データ取得
    const res = await Vue.prototype.$con.invoke(invokeRequest);

    // 最後かどうか
    isLast = !!(res.records.length < oneTimeRecords);

    // レコード追加
    resultRecords = [...resultRecords, ...res.records];

    // トータル件数更新
    totalSize = res.totalSize;

    // 進捗更新
    progress.value =
      totalSize < 1
        ? 100
        : Math.round((resultRecords.length / totalSize) * 100 * 10) / 10;
  }

  return {
    records: resultRecords,
    totalSize,
  };
};

/**
 * CSV形式にフォーマット
 * @param {Object} option オプション
 * @param {Array} option.records レコード
 * @param {Object} option.fieldSet 項目セット
 * @param {Object} option.objectInfo オブジェクト情報
 * @param {Object} option.csvOutputConfig 出力設定
 */
export const formatToCSV = async (option) => {
  const {
    records = [],
    fieldSet = {},
    objectInfo,
    csvOutputConfig = {},
  } = option;

  // ヘッダー
  const header = fieldSet.fields.map(
    (field) => objectInfo.properties[field.fieldPath].label,
  );

  // データ
  const rows = (records || []).map((record) => {
    // ヘッダー単位でまわす
    const res = fieldSet.fields.map((field) => {
      const { fieldPath } = field;
      const fieldInfo = objectInfo.properties[fieldPath];

      // 各項目に対してデータを変換
      // 対象のデータ
      const targetData = _.get(record, fieldPath, null);

      // データの変換が指定されていればそれを実施
      if (typeof csvOutputConfig[fieldPath] === 'function') {
        return csvOutputConfig[fieldPath](targetData, field, record);
      }

      // 種類によって分ける
      switch (fieldInfo.type) {
        case 'date-time': {
          return dateFnsPlugin.fnsFormat(targetData, null, 'P HH:mm');
        }
        case 'date': {
          return dateFnsPlugin.fnsFormat(targetData, null, 'P');
        }
        case 'boolean': {
          return _.isNil(targetData) ? null : String(targetData);
        }
        case 'location': {
          try {
            // パースできたらその値を返す
            const latlng = JSON.parse(targetData || '');
            if (
              latlng &&
              !_.isNil(latlng.latitude) &&
              !_.isNil(latlng.longitude)
            ) {
              return targetData;
            } else if (latlng.features) {
              const markerFeature = latlng?.features?.find(
                (f) => f?.geometry?.type === 'Marker',
              );
              if (markerFeature) {
                const latitude = markerFeature.geometry.coordinates?.lat;
                const longitude = markerFeature.geometry.coordinates?.lng;
                return JSON.stringify({ latitude, longitude });
              }
            }
          } catch (error) {
            // ignored
          }
          return null;
        }
        case 'picklist':
        case 'multipicklist': {
          const items = fieldInfo?.picklistValues;
          const values = targetData?.split?.(';') || [];
          if (items.length && values.length) {
            return values
              .map((v) => {
                const label = items.find((i) => i.value === v)?.label || v;
                return label;
              })
              .join(';');
          } else {
            return targetData;
          }
        }
        default: {
          return targetData;
        }
      }
    });
    return res;
  });

  let finalJson = [header, ...rows];

  // データの変換が指定されていればそれを実施
  if (typeof csvOutputConfig?.__final === 'function') {
    finalJson = await csvOutputConfig.__final(finalJson, {
      records,
      fieldSet,
      objectInfo,
    });
  }

  const csvString = stringify(finalJson);

  return csvString;
};
