<template functional>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        v-if="!props.list.editMode && props.canReload"
        icon
        small
        class="d-none d-sm-inline"
        @click="listeners['reload-data']()"
        v-on="on"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </template>
    <span>更新</span>
  </v-tooltip>
</template>

<script>
import Vue from 'vue';
import { VTooltip, VBtn, VIcon } from 'vuetify/lib';

Vue.component('VTooltip', VTooltip);
Vue.component('VBtn', VBtn);
Vue.component('VIcon', VIcon);

export default {
  name: 'Reload',
  props: {
    list: { type: Object, default: () => ({}) },
    canReload: { type: Boolean, default: true },
  },
};
</script>
