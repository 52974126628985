<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        v-if="!list.editMode && canCsvDownload"
        icon
        small
        class="ml-2 d-none d-sm-flex"
        @click="handleDownloadCsv"
        v-on="on"
      >
        <v-icon>mdi-download</v-icon>
        <v-overlay v-model="dialog.show" color="white">
          <v-progress-circular
            :size="progress.size + progress.indOver * 2"
            :width="progress.indOver"
            :color="progress.indColor"
            indeterminate
          >
          </v-progress-circular>
          <v-overlay :color="null">
            <v-progress-circular
              :rotate="-90"
              :size="progress.size"
              :width="progress.width"
              :value="progress.value"
              :color="progress.color"
            >
              {{ progress.value }}%
            </v-progress-circular>
          </v-overlay>
        </v-overlay>
      </v-btn>
    </template>
    <span>CSV出力</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';
import { downloadCsv, loadCsvRecords, formatToCSV } from '../csv';

export default {
  props: {
    /********** 共通 **********/
    // オブジェクト名
    objectName: { type: String, required: true },
    // オブジェクト情報
    objectInfo: { type: Object, required: true },

    /********** 一覧 **********/
    list: { type: Object, default: () => ({}) },
    // CSVファイル名フォーマット
    csvFileNameFormat: { type: String, default: null },
    // CSV項目セット名
    csvFieldSetName: { type: String, default: null },
    // ダウンロード可
    canCsvDownload: { type: Boolean, default: true },
    // CSV出力設定
    csvOutputConfig: {
      type: Object,
      default: () => ({}),
      /* sample
      {
        ThisIsCustomField__c: (value, fieldInfo, record) => {
          return value;
        }
      }
       */
    },
    // 一覧項目セット名
    listFieldSetName: { type: String, default: 'ListFieldSet' },
  },
  data: () => ({
    dialog: {
      show: false,
    },

    // CSV
    csv: {
      // 1回で取得するレコード数
      oneTimeRecords: 100,
    },

    // 読み込み
    progress: {
      size: 100,
      width: 15,
      value: 0,
      color: 'primary',
      indColor: 'primary lighten-4',
      indOver: 8,
    },
  }),
  methods: {
    // ダウンロードボタンをクリックした時に動く
    handleDownloadCsv() {
      this.downloadCsv();
    },

    // csvファイルをダウンロードさせる
    async downloadCsv() {
      this.dialog.show = true;
      try {
        // csvを生成
        const { csvString, totalSize } = await this.generateCsv();

        // ダウンロード
        await downloadCsv({
          csvString,
          totalSize,
          csvFileNameFormat: this.csvFileNameFormat,
        });
      } catch (error) {
        this.openSnackBar({
          message: 'CSVデータの取得に失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      } finally {
        this.dialog.show = false;
      }
    },

    // csv形式の文字列を作成
    async generateCsv() {
      // 項目セットを取得
      const fieldSet = await this.getCsvFieldSet();

      const { records, totalSize } = await loadCsvRecords({
        invokeRequest: structuredClone(this.list.invokeRequest),
        progress: this.progress,
        oneTimeRecords: this.csv.oneTimeRecords,
      });

      // フォーマット
      const csvString = await formatToCSV({
        records,
        fieldSet,
        objectInfo: this.objectInfo,
        csvOutputConfig: this.csvOutputConfig,
      });

      return { csvString, totalSize };
    },

    // 項目セットの取得
    async getCsvFieldSet() {
      // 2つの項目セットを取得する
      const [csvFieldSet, listFieldSet] = await Promise.all(
        [this.csvFieldSetName, this.listFieldSetName].map(
          async (fieldSetName) => {
            const { fieldSet } = await this.$util.getFieldSet(
              this.objectName,
              fieldSetName,
            );
            return fieldSet;
          },
        ),
      );
      // csvの項目セットを優先的に返す
      return csvFieldSet || listFieldSet;
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
