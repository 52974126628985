<template>
  <div
    :class="{
      fieldDisplay: true,
      [valueType]: true,
      [fieldPath]: true,
      'red--text': isMaxValue,
    }"
  >
    <template v-if="valueType === 'boolean'">
      <v-simple-checkbox
        v-if="computedValue === true || computedValue === false"
        v-model="computedValue"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-else-if="valueType === 'date-time'">
      {{ computedValue | datetime }}
    </template>
    <template v-else-if="valueType === 'date'">
      {{ computedValue | date }}
    </template>
    <template v-else-if="valueType === 'url'">
      <a :href="computedValue" target="_blank">{{ computedValue }}</a>
    </template>
    <template v-else>
      {{ computedValue }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'ListDisplay',
  model: {
    prop: 'value',
  },

  props: {
    value: {
      type: null,
      required: true,
    },
    itemProps: {
      type: Object,
      default: () => ({}),
    },
    fieldInfo: {
      type: Object,
      default: () => ({}),
    },
    // 表示形式の独自実装
    listDisplay: {
      type: Object,
      default: () => ({}),
      /* sample
      {
        ThisIsCustomField__c: (value, props) => {
          return `「${value}」`
        }
      }
       */
    },
    // 最大値か
    isMaxValue: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fieldPath() {
      return this.itemProps.header.value;
    },
    valueType() {
      const typeLowerCase = (this.fieldInfo.type || '').toLowerCase();
      return typeLowerCase;
    },
    computedValue() {
      // 表示形式が独自で定義されている場合はそれを採用する
      const fieldFormatter = (this.listDisplay || {})[this.fieldPath];
      if (fieldFormatter) {
        return fieldFormatter(this.value, this.itemProps);
      }

      switch (this.valueType) {
        // 位置情報はフォーマットを変える
        case 'location':
        case 'features': {
          try {
            const latlng = JSON.parse(this.value || '');
            if (
              latlng &&
              !_.isNil(latlng.latitude) &&
              !_.isNil(latlng.longitude)
            ) {
              return `${latlng.latitude}, ${latlng.longitude}`;
            } else if (latlng.features) {
              const markerFeature = latlng?.features?.find(
                (f) => f?.geometry?.type === 'Marker',
              );
              if (markerFeature) {
                const latitude = markerFeature.geometry.coordinates?.lat;
                const longitude = markerFeature.geometry.coordinates?.lng;
                return `${latitude}, ${longitude}`;
              }
            }
          } catch (error) {
            // ignored
          }
          return null;
        }
        // 選択リストはlabelの値を表示する
        case 'picklist':
        case 'multipicklist': {
          const items = this?.fieldInfo?.picklistValues;
          const values = this?.value?.split?.(';') || [];
          if (items.length && values.length) {
            return values
              .map((v) => {
                const label = items.find((i) => i.value === v)?.label || v;
                return label;
              })
              .join(';');
          }
          break;
        }
        // 数値は3桁区切り（numberは小数点が入るので3桁区切りにしていない）
        case 'integer': {
          return this?.value?.toLocaleString();
        }
        default: {
          break;
        }
      }
      return this.value;
    },
  },
};
</script>

<style lang="scss">
.cdsTable td {
  max-width: 30em;

  .fieldDisplay.textarea {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
<style scoped>
.integer {
  text-align: end !important;
}
</style>
