<template>
  <div v-if="computedAttachment.length === 0" class="text-center">
    <span>画像・動画はありません。</span>
  </div>
  <ImagePanel
    v-else
    :value="computedAttachment"
    @to-detail="$emit('to-detail', $event)"
  >
    <template #default="props">
      <slot v-bind="props"></slot>
    </template>
  </ImagePanel>
</template>

<script>
import ImagePanel from '@/components/common/ImagePanel';
import { getUrl } from '@/assets/js/s3.js';

export default {
  name: 'ListImage',
  components: {
    ImagePanel,
  },

  props: {
    // オブジェクト名
    objectName: {
      type: String,
      required: true,
    },
    // リクエスト
    invokeRequest: {
      type: Object,
      default: null,
    },
    // 表示中
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    // idの配列
    targetIds: [],
    // 添付ファイルのrawデータ
    objects: [],
    // 読み込みに使った最後のinvokeRequest
    lastInvokeRequest: null,
  }),

  computed: {
    // 画像データ整形
    computedAttachment() {
      return (
        this.objects
          .map((obj) => ({
            ...obj,
            // attachmentはフィルタリングをかける
            attachments: (obj.attachments || [])
              .filter(
                (v) =>
                  v.Type?.split('/')[0] === 'image' ||
                  v.Type?.split('/')[0] === 'video',
              )
              .map((v) => ({
                ...v,
                src: v.url,
              })),
          }))
          // attachmentがあるものだけを取り出し
          .filter((v) => v.attachments && v.attachments.length !== 0)
      );
    },
  },

  watch: {
    isActive(to, from) {
      if (to && !from) {
        if (!_.isEqual(this.invokeRequest, this.lastInvokeRequest)) {
          this.$store.dispatch('loading/register', this.init());
        }
      }
    },
  },

  methods: {
    async init() {
      await this.loadRecordIds();
      await this.loadAttachment();
    },
    // リクエスト
    async loadRecordIds() {
      if (!this.invokeRequest) return;

      const request = structuredClone(this.invokeRequest);

      // 取得する項目をIdだけにする
      const sortKey = request.params.listOptions.sortBy[0];
      request.params.listProperty.selectFields = [
        ...new Set(['Id', sortKey].filter((v) => !!v)),
      ];

      // ページングを削除する
      // ページ番号
      request.params.listOptions.page = 1;
      // 1ページあたりの表示件数
      request.params.listOptions.itemsPerPage = null;

      // データ取得
      const res = await this.$con.invoke(request);
      // リクエストを保持
      this.lastInvokeRequest = structuredClone(this.invokeRequest);

      // 何もデータがない場合は処理を終わる
      if (!res || !res.records || res.records.length === 0) return;

      // idのリストとして抜き出し
      this.targetIds = res.records
        .map(({ Id }) => Id)
        .filter((v) => v !== null && v !== undefined);
    },

    // 添付ファイルのデータを取得する
    async loadAttachment() {
      const res = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller: 'CDS_CTR_Common',
          method: 'getAttachment',
          params: {
            objectName: this.objectName,
            ids: JSON.stringify(this.targetIds),
          },
        }),
      );
      if (!Array.isArray(res)) return;
      // console.log('%cattach res ->', 'color: GoldenRod;', res);
      this.objects = res || [];
      await Promise.all(
        this.objects.map(async (d) => {
          if (d.attachments) {
            await Promise.all(
              d.attachments.map(async (attachment) => {
                attachment.url = await getUrl(attachment.FileId);
              }),
            );
          }
        }),
      );
      this.objects = [...this.objects];
    },
  },
};
</script>
