<template>
  <div class="fill-height pt-4">
    <div>
      <Search v-bind="computedSearchProps" @search="handleSearch">
        <template #activator="{on, attrs, openDialog}">
          <v-btn
            v-bind="attrs"
            color="primary"
            depressed
            v-on="on"
            @click="openDialog"
          >
            <v-icon left>
              mdi-clock-end
            </v-icon>
            時点情報作成
          </v-btn>
        </template>
      </Search>
    </div>
    <ListTable
      v-if="Object.keys(objectInfo).length > 0"
      v-bind="computedTableProps"
      @update:editMode="handleUpdateEditMode"
      @update:invoke-request="handleUpdateInvokeRequest"
      @click-row="handleClickRow"
      @load-completed="$emit('load-completed', $event)"
    >
      <template
        v-for="slotName of slots.datatable"
        #[`datatable.${slotName}`]="props"
      >
        <slot :name="`datatable.${slotName}`" v-bind="props"></slot>
      </template>
    </ListTable>
    <!-- 一覧編集 -->
    <Detail
      v-if="detailLayoutName"
      v-model="detail"
      :object-name="objectName"
      :object-info="objectInfo"
      :detail-layout-name="detailLayoutName"
    />
  </div>
</template>

<script>
import { getSlots, invokeCreateTotalRequest } from './util';
import Detail from './Detail.vue';
import Search from './search/index.vue';
import ListTable from './ListTable.vue';
import { mapActions } from 'vuex';

export default {
  name: 'TotalView',
  components: {
    Search,
    Detail,
    ListTable,
  },
  props: {
    /********** 共通 **********/
    // オブジェクト名
    objectName: { type: String, required: true },
    // 集計対象のオブジェクト名
    targetObjectName: { type: String, required: true },
    // 災害関連
    isDisasterRelated: { type: Boolean, default: false },
    // 一覧コンポーネント名(一意な英数字)
    listName: { type: String, default: null },
    // バージョン情報を持っているか
    hasVersion: { type: Boolean, default: false },

    /********** 詳細 **********/
    // 詳細ページ名
    detailPageName: { type: String, default: null },
    // 詳細編集レイアウト名
    detailLayoutName: { type: String, default: null },
    // 詳細遷移
    handleClickRowFunc: { type: Function, default: null },

    /********** 一覧 **********/
    // テーブルに渡すプロパティ
    tableProps: { type: Object, default: () => ({}) },
    /********** 検索 **********/
    // 検索に渡すプロパティ
    searchProps: { type: Object, default: () => ({}) },
  },
  data: () => ({
    objectInfo: {},
    targetObjectInfo: {},

    // 検索
    search: null,

    // リスト
    list: {
      editMode: false,
      invokeRequest: null,
    },

    // 詳細
    detail: {
      dialog: false,
      item: null,
    },
  }),
  computed: {
    // テーブルに渡すプロパティ
    computedTableProps() {
      return {
        canCreate: false,
        canFix: false,
        canDelete: true,
        ...this.tableProps,
        objectName: this.objectName,
        objectInfo: this.objectInfo,
        searchData: this.search,
        detailPageName: this.detailPageName,
        listName: this.listName,
        le: true,
        hasVersion: this.hasVersion,
        applyMaxFields: true,
      };
    },
    // 検索に渡すプロパティ
    computedSearchProps() {
      return {
        layoutName: 'TotalSearchLayout',
        ...this.searchProps,
        objectName: this.targetObjectName,
        disabled: this.list.editMode,
        listName: this.listName,
        le: false,
        execButtonName: '時点情報作成',
        showBaseDatetime: true,
        baseDatetimeDefaultValue: this.$dateFns.fnsFormat(
          new Date(),
          '',
          "yyyy-MM-dd'T'HH:mm:ss+09:00",
        ),
      };
    },

    // スロット
    slots() {
      return ['datatable'].reduce((prev, next) => {
        return {
          ...prev,
          [next]: getSlots(next, this.$slots, this.$scopedSlots),
        };
      }, {});
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.loadObjectInfo();
      this.loadTable();
    },

    /********** 初期 **********/
    async loadObjectInfo() {
      const [objectInfo, targetObjectInfo] = await Promise.all(
        [this.objectName, this.targetObjectName].map(async (name) => {
          if (!name) return {};
          return this.$util.getObjectInfo(name);
        }),
      );
      this.$set(this, 'objectInfo', objectInfo);
      this.$set(this, 'targetObjectInfo', targetObjectInfo);
    },

    /********** 検索 **********/
    // 検索ボタンが押された時
    async handleSearch(searchData) {
      await this.$store.dispatch(
        'loading/register',
        this.createTotal(searchData),
      );
    },

    // 時点情報の作成
    async createTotal({
      callType,
      searchObject,
      targetCondition,
      baseDatetime,
      baseDatetimeFieldName,
    }) {
      // クリックで動作してない時は無視する
      if (callType !== 'submit') return;

      if (!baseDatetime) {
        this.openErrorSnackBar({
          message: '基準日時は必須です。',
        });
        return;
      }

      try {
        const res = await invokeCreateTotalRequest({
          controller: this.$pageProperty.controller,
          method: 'createTotal',
          objectName: this.targetObjectName,
          searchObject,
          disasterId: this.$store.state.disaster.disaster.Id,
          customListProperty: {
            baseDatetime,
            baseDatetimeFieldName,
          },
          paramsExtension: {
            totalObjectName: this.objectName,
            targetOrganization:
              targetCondition.Ward__c?.join(',') || '指定なし',
          },
        });
        console.log('res', res);
        this.saveComplete('時点情報を作成しました。');
        // 一覧表を再読み込み
        this.loadTable();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    // 一覧表の読み込み
    loadTable() {
      // これをトリガに一覧表の検索が走る
      this.$set(this, 'search', {});
    },

    /********** 共通 **********/

    // editModeが変更された時
    handleUpdateEditMode(editMode) {
      this.list.editMode = editMode;
    },

    // 行がクリックされた時
    handleClickRow(item) {
      if (this.list.editMode) {
        this.$set(this.detail, 'item', item);
        this.$set(this.detail, 'dialog', true);
      } else {
        if (this.handleClickRowFunc) {
          this.handleClickRowFunc(item);
          return;
        }
        this.toDetail(item);
      }
    },

    // リクエストが更新された時
    handleUpdateInvokeRequest(invokeRequest) {
      this.$set(this.list, 'invokeRequest', invokeRequest);
    },

    /********** 詳細 **********/

    // 詳細に遷移
    toDetail(item) {
      if (item.Id) {
        this.transitionDetailPage({
          id: item.Id,
        });
      }
    },

    // 詳細ページに移動
    transitionDetailPage(options) {
      if (this.detailPageName) {
        this.$transition.to(this.detailPageName, options);
      }
    },

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
};
</script>

<style>
.info--text .v-alert__content {
  font-size: 13px !important;
}
</style>
