<template functional>
  <Confirm
    v-if="props.list.editMode"
    message="入力内容を破棄します。よろしいですか？"
    @on-click-yes="listeners['cancel-list-edit']()"
  >
    <template #default="{ on }">
      <v-btn depressed class="mr-3" v-on="on">
        <v-icon left>
          mdi-cancel
        </v-icon>
        <span>キャンセル</span>
      </v-btn>
    </template>
  </Confirm>
</template>

<script>
import Vue from 'vue';

import { VBtn, VIcon } from 'vuetify/lib';
import Confirm from '@/components/common/Confirm';

Vue.component('VBtn', VBtn);
Vue.component('VIcon', VIcon);
Vue.component('Confirm', Confirm);

export default {
  name: 'CancelEdit',
  props: {
    list: { type: Object, default: () => ({}) },
  },
};
</script>
