<template>
  <v-dialog v-model="dialog" scrollable max-width="1200px">
    <v-card>
      <v-card-title>
        <span class="text-h5">一覧編集</span>
      </v-card-title>
      <v-card-text class="py-0 px-2">
        <Layout
          v-model="editItem"
          :name="formName"
          :layout="layout"
          :object-info="objectInfo"
          :edit-mode="true"
          :show-required-help="false"
          :card-props="{
            elevation: 0,
            class: 'listDetailCard',
          }"
          @submit-raw="validate"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          閉じる
        </v-btn>
        <v-btn
          color="blue darken-1"
          dark
          depressed
          @click="$formulate.submit(formName)"
        >
          反映
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Layout from '@/components/detail/Layout';
import { mapActions } from 'vuex';

export default {
  name: 'ListDetail',
  components: {
    Layout,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    // オブジェクト名
    objectName: {
      type: String,
      required: true,
    },
    objectInfo: {
      type: Object,
      required: true,
    },
    // 詳細編集レイアウト名
    detailLayoutName: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    // フォーム名
    formName: _.uniqueId('listview_detail_form-'),
    // レイアウト
    layout: {},
    // 編集対象
    editItem: {},
  }),

  computed: {
    // 編集値
    editValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    // ダイアログ
    dialog: {
      get() {
        return this.editValue.dialog;
      },
      set(value) {
        this.editValue.dialog = value;
      },
    },
    // アイテム
    item: {
      get() {
        return this.editValue.item;
      },
      set(newValue) {
        this.editValue.item = newValue;
      },
    },
  },

  watch: {
    dialog(to, from) {
      // ダイアログを開こうとした時
      if (to && !from) {
        this.$nextTick(() => {
          // 値が残るので一回クリアする
          this.$formulate.setValues(this.formName, {});
          // アイテムをコピーする
          this.$formulate.setValues(this.formName, structuredClone(this.item));
        });
      }
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      try {
        await Promise.all([this.loadLayout()]);
      } catch (error) {
        this.openSnackBar({
          message: 'データのロードに失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },
    /**
     * レイアウト読み出し
     */
    async loadLayout() {
      const { detailLayoutName, objectName } = this;
      if (!objectName || !detailLayoutName)
        throw new Error('objectName or layoutName is not defined');
      const { layout } = await this.$util.getLayout(
        objectName,
        detailLayoutName,
      );
      this.$set(this, 'layout', layout);
    },

    // 閉じる
    close() {
      this.dialog = false;
    },

    // バリデート
    async validate(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }
        this.apply();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    // 一覧にapplyする
    apply() {
      // 変更されたものだけを取り出し
      const diff = _.omitBy(this.editItem, (v, k) =>
        _.isEqual(this.item[k], v),
      );
      // itemに値を設定
      Object.entries(diff).map(([fieldPath, value]) => {
        this.$set(this.item, fieldPath, value);
      });
      this.close();
    },

    ...mapActions('snackbar', ['openSnackBar', 'saveFail']),
  },
};
</script>

<style lang="scss">
.v-application div.v-card.listDetailCard div.v-card__text {
  padding-top: 0 !important;
}
</style>
