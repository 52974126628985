<template>
  <v-btn
    v-if="!list.editMode && computedCanCreate"
    depressed
    outlined
    color="primary"
    class="mr-3"
    @click="$emit('create')"
  >
    <v-icon left>mdi-plus</v-icon>
    <span>{{ createButtonName }}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    list: { type: Object, default: () => ({}) },
    canCreate: { type: Boolean, default: true },
    createButtonName: { type: String, default: '新規作成' },
    le: { type: Boolean, default: undefined },
  },
  data: () => ({}),
  computed: {
    computedCanCreate() {
      return (
        this.le !== false &&
        this.canCreate &&
        !!this.$store.state.user.user.Permission__c
      );
    },
  },
};
</script>
