/**
 * オブジェクト情報を検索用に変換する
 * @param {Object} objectInfo オブジェクト情報
 * @returns
 */
export function convertObjectInfoForSearch({ objectInfo }) {
  Object.entries(objectInfo.properties).map(([fieldPath, fieldInfo]) => {
    fieldInfo.fieldPath = fieldPath;

    const { type } = fieldInfo;

    /**
     * 項目共通
     */

    // デフォルト値を設定させない
    fieldInfo.notApplyDefaultValue = true;
    // ヘルプは無効化する
    fieldInfo.helpText = null;

    /**
     * 項目の種類ごとに適用
     */

    // 数値 number
    // 選択 picklist
    // 複数 multipicklist
    // 日付時間 date-time
    // 日付 date

    // 選択するものは複数選択にする
    if (['picklist', 'reference'].includes(type)) {
      fieldInfo.multiple = true;
    }

    // 選択肢に「なし」を追加
    if (['picklist'].includes(type) && !fieldInfo.noNullValue) {
      fieldInfo.picklistValues = [
        {
          defaultValue: false,
          label: '(なし)',
          value: null,
        },
        ...fieldInfo.picklistValues,
      ];
    }

    // 範囲検索にする
    if (['number', 'integer', 'date-time', 'date'].includes(type)) {
      fieldInfo.originType = type;
      fieldInfo.type = 'range';
    }
  });

  return objectInfo;
}

/**
 * 検索オブジェクトを生成する
 * @param {Object} param0.objectInfo オブジェクト情報
 * @param {Object} param0.condition フォームの条件
 * @param {Array} param0.selectedFields 選択済み項目
 * @param {Boolean} param0.forceCondition 強制的に付与する条件
 * @param {Boolean} param0.le listedit
 * @returns
 */
export async function generateSearchObject({
  objectInfo,
  condition,
  selectedFields,
  forceCondition,
  le,
}) {
  // 対象とする検索条件
  const targetCondition = Object.entries(condition)
    .map(([fieldPath, value]) => ({ fieldPath, value }))
    .filter(
      ({ fieldPath, value }) =>
        selectedFields.includes(fieldPath) && hasConditionValue(value),
    )
    .reduce((prev, { fieldPath, value }) => {
      return {
        ...prev,
        [fieldPath]: value,
      };
    }, {});

  const hasCondition = !!Object.keys(targetCondition).length;

  let searchObject = {};

  if (hasCondition) {
    // 入力された条件を反映
    searchObject = {
      ...searchObject,
      AND: [
        ...(searchObject.AND || []),
        ...Object.entries(targetCondition).map(([fieldPath, value]) => {
          return toPrismaSearch(
            value,
            fieldPath,
            objectInfo.properties[fieldPath],
          );
        }),
      ],
    };
  }

  // 強制的に付与する条件がある場合はその処理をする
  if (forceCondition) {
    switch (typeof forceCondition) {
      // 関数の場合
      case 'function': {
        // 検索条件等を渡して戻り値をセット
        searchObject = await forceCondition({
          objectInfo,
          condition,
          selectedFields,
          searchObject,
          targetCondition,
          hasCondition,
          le,
        });
        break;
      }
      // オブジェクトの場合
      case 'object': {
        // 検索オブジェクトにうしろから追加する
        searchObject = {
          ...searchObject,
          ...forceCondition,
        };
        break;
      }
      default: {
        break;
      }
    }
  }

  return {
    searchObject,
    targetCondition,
    hasCondition,
  };
}

/**
 * 検索条件が指定されているか調べる
 * @param {*} value value
 * @returns
 */
function hasConditionValue(value) {
  if (Array.isArray(value)) {
    // 配列のときは中身があるときだけ有効
    return value.length !== 0;
  } else if (isObject(value)) {
    // オブジェクトのときはキーがある場合のみ有効
    return Object.keys(value).length !== 0;
  }
  return true;
}

function isObject(value) {
  return value !== null && typeof value === 'object';
}

/**
 * Prisma式の検索条件に変換する
 * https://www.prisma.io/docs/reference/api-reference/prisma-client-reference
 * @param {*} value value
 * @param {String} fieldPath 項目名
 * @param {Object} fieldInfo 項目情報
 * @returns
 */
function toPrismaSearch(value, fieldPath, fieldInfo) {
  const { type } = fieldInfo;

  switch (type) {
    case 'string':
    case 'address':
    case 'email':
    case 'phone': {
      return {
        [fieldPath]: {
          contains: value,
        },
      };
    }
    case 'boolean': {
      return {
        [fieldPath]: value,
      };
    }
    case 'range': {
      return {
        [fieldPath]: value,
      };
    }
    case 'array':
    case 'reference':
    case 'picklist': {
      if (value.includes(null)) {
        return {
          OR: [
            {
              [fieldPath]: {
                in: value.filter((v) => v !== null),
              },
            },
            {
              [fieldPath]: {
                equals: null,
              },
            },
          ],
        };
      } else {
        return {
          [fieldPath]: {
            in: value,
          },
        };
      }
    }

    default: {
      return {
        [fieldPath]: value,
      };
    }
  }
}
